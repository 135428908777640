import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Personal from './personal';
import Address from './address';
import Review from './review';
import CameraDialog from './imageCapture';
import { useData } from './DataContext';
import { baseUrl } from '../../App';
import * as faceapi from 'face-api.js';
import LoadingOverlay from '../loading';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://flashfotosformaturas.com.br/index.html">
        Flash Fotos Formaturas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Informações Pessoais', 'Endereço', 'Revise seus dados'];
const required_personal_fields = ['primeiroNome', 'sobrenome', 'cpf', 'dataNascimento', 'id_turma']
const required_personal_add = ['cep', 'endereco', 'numero', 'bairro', 'cidade', 'uf', 'celular']
const required_parental_add = ['cepPais', 'enderecoPais', 'numeroPais', 'bairroPais', 'cidadePais', 'ufPais', 'celularPais']

function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g,''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || !/^\d{11}$/.test(cpf)) return false; // Verifica se o CPF tem 11 dígitos
    
    // Verifica se todos os dígitos são iguais
    var i;
    for (i = 1; i < 11; i++) {
        if (cpf.charAt(i) !== cpf.charAt(0)) break;
    }
    if (i === 11) return false;
    
    // Calcula o primeiro dígito verificador
    var soma = 0;
    for (i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    var resto = 11 - (soma % 11);
    var dv = (resto >= 10) ? 0 : resto;
    if (dv !== parseInt(cpf.charAt(9))) return false;
    
    // Calcula o segundo dígito verificador
    soma = 0;
    for (i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    dv = (resto >= 10) ? 0 : resto;
    if (dv !== parseInt(cpf.charAt(10))) return false;
    
    return true;
}



export default function Checkout() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [openImageDialog, setOpenImageDialog] = React.useState(true);
    const [capturedImage, setCapturedImage] = React.useState(null);
    const params = useParams();
    const { formData, updateFormData } = useData({ token: params.turma });
    const [ onLoad, setOnLoad ] = React.useState(false);
    const [ sending, setSending ] = React.useState(false);

    const handleCapture = (imageSrc) => {
      setCapturedImage(imageSrc);
      updateFormData({ image: imageSrc });
    };

    function getStepContent(step) {
      switch (step) {
        case 0:
          return <Personal />;
        case 1:
          return <Address />;
        case 2:
          return <Review />;
        default:
          throw new Error('Unknown step');
      }
    }
  
    const handleSend = async () => {
      setOnLoad(true);
      // Implementar a lógica para enviar a imagem
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceRecognitionNet.loadFromUri('/models');

      // Crie um elemento de imagem a partir da string base64
      const img = document.createElement('img');
      img.src = capturedImage;

      // Esperar que a imagem seja carregada
      await new Promise(resolve => img.onload = resolve);

      const detection = await faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions());
      if (detection.length > 0) {
        try {
          fetch(baseUrl + 'users/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          }).then(response => {
            if (response.ok) {
              setOpenImageDialog(false);
              return response.json()
            } else {
              return response.json()
            }
          }).then(data => {
            if (data.status !== 'success') {
              setActiveStep(2)
              alert('Falha ao enviar dados para o sistema. Motivo: ' + data.message);
              if (data.message === 'Usuário já cadastrado!'){
                window.location.reload()
              }
              setSending(false);
            }
          })
        } catch (error) {
          console.error('Erro ao enviar dados para a API:', error);
          setSending(false);
        }
      } else {
        alert('Por favor, tire uma foto do rosto!');
      }
      setOnLoad(false);
    };

    const handleSend_click = () =>{
      if (!sending) {
        setSending(true);
        setTimeout(handleSend, 2000);
      } else {
        alert("Enviando dados para a api!");
      }
    };
  
    const handleRetake = () => {
      setCapturedImage(null);
    };
    
    //Function to validate fields on form
    function verifyFields (fields_list) {
      let next = true;
      if (fields_list.length > 0){
        for (const field of fields_list) {
          if (!(field in formData) || (formData[field]=== undefined) || (formData[field]=== '')){
            updateFormData({[field + '_err']: true});
            next = false;
          }
          else {
            updateFormData({[field + '_err']: false});
          }
          if ((field === 'cpf') && next){
            if (validarCPF(formData.cpf)) {
              updateFormData({[field + '_err']: false});
            } else {
              updateFormData({[field + '_err']: true});
              next = false;
              alert("O CPF [" + formData.cpf + "] não é válido!");
            }
          }
        }
      }
      return next;
    }

    const handleNext = () => {
      window.scrollTo(0,0);
      let verify_fields = [];
      if (activeStep === 0) {
        verify_fields = required_personal_fields;
      }else if(activeStep === 1){
        verify_fields = required_personal_add;
        if (!('moraComPais' in formData) || (formData.moraComPais === undefined) || (formData.moraComPais)) {
          updateFormData({moraComPais: true}); 
        }else{
          verify_fields = verify_fields.concat(required_parental_add);
        }
      }

      let next = verifyFields(verify_fields);
      if (next) {
        setActiveStep(activeStep + 1);
      }else{
        alert("Preencha todos os campos obrigatórios!");
      }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <React.Fragment>
        <CssBaseline />
        <AppBar
            position="absolute"
            color="default"
            elevation={0}
            sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
        >
        </AppBar>
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
                Pré cadastro de formando
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
              {openImageDialog === true ? (
                <CameraDialog
                  open={openImageDialog}
                  capturedImage={capturedImage}
                  onCapture={handleCapture}
                  onSend={capturedImage ? () => {handleSend_click()} : null}
                  onRetake={handleRetake}
                />
              ) : (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                      Dados enviados
                  </Typography>
                  <Typography variant="subtitle1">
                      Seus dados foram enviados com sucesso! 
                  </Typography>
                </React.Fragment>
              )}
              </React.Fragment>
            ) : (
                <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Voltar
                    </Button>
                    )}

                    <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                    >
                    {activeStep === steps.length - 1 ? 'Enviar' : 'Próximo'}
                    </Button>
                </Box>
                </React.Fragment>
            )}
            </Paper>
            <Copyright />
            <LoadingOverlay open={onLoad}/>
        </Container>
        </React.Fragment>
    );
}