import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Grid } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';
import DropzoneComponent from './uploadDropzone';
import GalleryDialog from './gerenciarGaleria';
import LoadingOverlay from '../loading';

const UserProfileDialog = ({ userId, open, onClose }) => {
  const [userData, setUserData] = useState(null);
  const [uploadDropzoneOpen, setUploadDropzoneOpen] = useState(false);
  const { admin } = React.useContext(AuthContext);
  const [ galleryDialogOpen, setGalleryDialogOpen ] = useState(false);
  const [ openCredential, setOpenCredential ] = useState(false);
  const [onLoad, setOnLoad] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId !== '') {
        try {
          setOnLoad(true);
          const response = await axios.get(`${baseUrl}admins/get-user?userId=${userId}`, {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          });
          setUserData(response.data);
          setOnLoad(false);
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error);
          alert("Erro ao buscar informações do formando!");
          setOnLoad(false);
          onClose();
        }
      }
    };
    if (userData?.pessoal?.id !== userId){
      fetchUserData();
    }
  }, [open]);

  const handleUploadClose = () => {
    setUploadDropzoneOpen(false);
  };

  const handleGalleryDialogClose = () => {
    setGalleryDialogOpen(false);
  };

  const getDateString = (dataString) => {
    const data = new Date(dataString);

    // Formatar a data para "DD/MM/YYYY"
    const dia = data.getUTCDate().toString().padStart(2, '0');
    const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();

    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  };

  const getDateTimeString = (dataString) => {

    let dataFormatada;
    if (dataString !== null){
      const data = new Date(dataString);

      // Formatar a data para "DD/MM/YYYY"
      const dia = data.getUTCDate().toString().padStart(2, '0');
      const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
      const ano = data.getFullYear();
      const hora = data.getHours() + 3;
      const minuto = data.getMinutes();
      const segundo = data.getSeconds()

      dataFormatada = `${dia}/${mes}/${ano} - ${hora}:${minuto}:${segundo}`;
    } else {
      dataFormatada = '';
    }
    
    return dataFormatada;
  };

  const downloadProfilePicture = (userData) => {
    // Cria um link temporário para a imagem
    const link = document.createElement('a');
    link.href = `data:image/jpeg;base64, ${userData.profile_image}`;

    // Define o atributo "download" para forçar o download da imagem
    link.download = `${userData.pessoal?.primeiroNome}_${userData.pessoal?.id}.jpg`;

    // Simula um clique no link para iniciar o download
    document.body.appendChild(link);
    link.click();

    // Remove o link temporário
    document.body.removeChild(link);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogContent>
        <Grid container spacing={2} >
          <Grid item xs={6}>
            {userData && (
              <img src={`data:image/jpeg;base64, ${userData.profile_image}`} alt="Foto de Perfil" style={{ maxWidth: '100%', maxHeight: '100%',  marginTop: '5%' }} />
            )}
          </Grid>
          <Grid item xs={6} style={{ marginTop: '2%' }}>
            <Grid container spacing={2}>
              {userData && (
                <>
                <Grid item xs={userData?.endereco?.cepPais ? (6):(12)}>
                  <Typography variant="h6" style={{ marginBottom: '10px' }}><b>Dados pessoais</b></Typography>
                  <Typography><b>Nome:</b> {userData.pessoal?.primeiroNome}</Typography>
                  <Typography><b>Sobrenome:</b> {userData.pessoal?.sobrenome}</Typography>
                  <Typography><b>RG:</b> {userData.pessoal?.rg}</Typography>
                  <Typography><b>CPF:</b> {userData.pessoal?.cpf}</Typography>
                  <Typography><b>Email:</b> {userData.pessoal?.email}</Typography>
                  <Typography><b>Data de Nascimento:</b> {userData.pessoal?.dataNascimento ? (getDateString(userData.pessoal.dataNascimento)):(null)}</Typography>
                  <Typography><b>CEP:</b> {userData?.endereco?.cep}</Typography>
                  <Typography><b>Endereço:</b> {userData?.endereco?.endereco}, {userData?.endereco?.numero}, {userData?.endereco?.complemento}</Typography>
                  <Typography><b>Bairro:</b> {userData?.endereco?.bairro}</Typography>
                  <Typography><b>Cidade:</b> {userData?.endereco?.cidade}</Typography>
                  <Typography><b>UF:</b> {userData?.endereco?.uf}</Typography>
                  <Typography><b>Celular 1:</b> {userData?.endereco?.celular}</Typography>
                  <Typography><b>Celular 2:</b> {userData?.endereco?.celular_second}</Typography>
                  <Typography><b>Último download:</b> {userData.pessoal?.dataDownload ? (getDateTimeString(userData.pessoal.dataDownload)):(null)}</Typography>
                </Grid>
                  {/* Renderiza os dados de endereço dos pais se o campo cepPais estiver presente */}
                  {userData?.endereco?.cepPais && (
                    <Grid item xs={userData.endereco.cepPais ? (6):(12)}>
                      <Typography variant="h6" style={{ marginBottom: '10px' }}><b>Endereço dos Pais</b></Typography>
                      <Typography><b>CEP:</b> {userData.endereco.cepPais}</Typography>
                      <Typography><b>Endereço:</b> {userData.endereco.enderecoPais}, {userData.endereco.numeroPais}, {userData.endereco.complementoPais}</Typography>
                      <Typography><b>Bairro:</b> {userData.endereco.bairroPais}</Typography>
                      <Typography><b>Cidade:</b> {userData.endereco.cidadePais}</Typography>
                      <Typography><b>UF:</b> {userData.endereco.ufPais}</Typography>
                      <Typography><b>Celular:</b> {userData.endereco.celularPais}</Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            
          </Grid>
        </Grid>
        <div style={{ marginTop: '20px' }}>
          <Button variant="contained" onClick={() => {setOpenCredential(true)}}>Ver credenciais de acesso</Button>
          <Button variant="contained" style={{ marginLeft: '10px' }} onClick={() => {setUploadDropzoneOpen(true)}}>Upload de imagens</Button>
          <Button variant="contained" style={{ marginLeft: '10px' }} onClick={() => {setGalleryDialogOpen(true)}}>Gerenciar fotos</Button>
          <Button variant="contained" style={{ marginLeft: '10px' }} onClick={() => {downloadProfilePicture(userData)}}>Baixar foto de perfil</Button>
        </div>
      </DialogContent>
      <DropzoneComponent userId={userId} username={userData ? (`${userId} - ${userData.pessoal.primeiroNome}`):('')} open={uploadDropzoneOpen} onClose={handleUploadClose}/>
      <GalleryDialog  open={galleryDialogOpen} handleClose={handleGalleryDialogClose} user_id={userId}/>
      <Dialog open={openCredential} onClose={() => {setOpenCredential(false)}}>
        <DialogTitle><b>Credenciais de acesso do formando</b></DialogTitle>
        <DialogContent>
        {userData && (
          <>
          <Typography variant="body1"><b>Nome de Usuário:</b> {userData.pessoal.id}</Typography>
          <Typography variant="body1"><b>Senha:</b> {userData.pessoal.id.toString().slice(-3)}</Typography>
          <Typography >A senha de acesso do formando é formada pelos três últimos dígitos do número do formando!</Typography>
          </>
        )}
        </DialogContent>
      </Dialog>
      <LoadingOverlay open={onLoad}/>
    </Dialog>
  );
};

export default UserProfileDialog;
