import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';


export const mainListItems = (paginaSelecionada, handleItemClick) => (
  <React.Fragment>
    <ListItemButton selected={paginaSelecionada === 'Galeria'} onClick={() => handleItemClick('Galeria')}>
      <ListItemIcon>
        <PhotoLibraryIcon />
      </ListItemIcon>
      <ListItemText primary="Galeria" />
    </ListItemButton>
  </React.Fragment>
);
