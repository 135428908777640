import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import Webcam from 'react-webcam';



const CameraDialog = ({ open, capturedImage, onCapture, onSend, onRetake }) => {

    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
      };

    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setTimeout(() => {
            onCapture(imageSrc);
          }, 20);
    }, [webcamRef, onCapture]);

    return (
        <Dialog open={open} onClose={() => {}} PaperProps={{
                                                        style: {
                                                        boxShadow: 'none',
                                                        overflow: 'hidden',
                                                        width: '100%',
                                                        height: '80%',
                                                        margin: 0,
                                                        },
                                                    }}>
        <DialogContent
            sx={{
                overflow: 'hidden',
                height: '80%',
                display: 'flex',
                flexDirection: 'column',
                }}
            >
            {!capturedImage ? (
                <>
                    <Webcam
                        width={1280}
                        height={720}
                        audio={false}
                        ref={webcamRef}
                        mirrored = {true}
                        videoConstraints={videoConstraints}
                        screenshotFormat="image/jpeg"
                        style={{ width: '100%', maxHeight: '100%' }}
                        screenshotQuality={1}
                        imageSmoothing={false}
                    />
                </>
            ) : (
                
                <div>
                    <img src={capturedImage} alt="Captured" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
            )}
        </DialogContent>
        <DialogActions>
            {!capturedImage ? (
                <Button onClick={capture} variant="contained" color="primary" xs={12}>
                    Tirar Foto
                </Button>
            ):(
                <>
                <Button onClick={onRetake} color="primary" sx={{ flex: 1, marginLeft: '8px' }}>
                    Refazer Foto
                </Button>
                <Button onClick={onSend} variant="contained" color="primary" sx={{ flex: 1, marginLeft: '8px' }}>
                    Enviar
                </Button>
                </>
            )}
        </DialogActions>
        </Dialog>
    );
    };

export default CameraDialog;