import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';

function CadastroInstituicao({ open, onClose, type, inst_data }) {
  const [nome, setNome] = useState('');
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [numero, setNumero] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUF] = useState('');
  const { admin } = React.useContext(AuthContext);

  useEffect(() => {
    if (cep.length === 8) {
      axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {
          const { logradouro, bairro, localidade, uf } = response.data;
          setEndereco(logradouro);
          setBairro(bairro);
          setCidade(localidade);
          setUF(uf);
        })
        .catch(error => {
          console.error('Erro ao obter dados do CEP:', error);
        });
    }
  }, [cep]);

  useEffect(() => {
    setNome(inst_data.nome || '');
    setCep(inst_data.cep || '');
    setEndereco(inst_data.endereco || '');
    setBairro(inst_data.bairro || '');
    setNumero(inst_data.numero || '');
    setCidade(inst_data.cidade || '');
    setUF(inst_data.uf || '');
  }, [inst_data, type]);

  const handleCancelar = () => {
    setNome('');
    setBairro('');
    setCep('');
    setCidade('');
    setEndereco('');
    setNumero('');
    setUF('');
    onClose();
  };

  const handleCadastrarEditar = () => {
    if (type === 'cadastro') {
        axios.post(baseUrl + 'admins/register-institution',{
            nome: nome,
            cep: cep,
            endereco: endereco,
            bairro: bairro,
            numero: numero,
            cidade: cidade, 
            uf: uf
          }, {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          })
            .then(response => {
              alert("Instituição adicionada com sucesso!");
            })
            .catch(error => {
              alert('Erro:', error);
            });
    } else {
        axios.post(baseUrl + 'admins/register-institution',{
          id: inst_data.id,
          nome: nome,
          cep: cep,
          endereco: endereco,
          bairro: bairro,
          numero: numero,
          cidade: cidade, 
          uf: uf
        }, {
          headers:{
            "Authorization": "Bearer "+admin.idToken
          }
        })
          .then(response => {
            alert("Instituição atualizada com sucesso!");
          })
          .catch(error => {
            alert('Erro:', error);
          });
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancelar}>
      <DialogTitle><b>{type === 'cadastro' ? 'Cadastro de' : (type === 'editar' ? 'Editar': 'Registro de')} Instituição</b></DialogTitle>
      <DialogContent>
        <Grid container spacing={3} gutterBottom>
            <Grid item xs={12}>
                <TextField
                    label="Nome"
                    fullWidth
                    variant="standard"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="CEP"
                    fullWidth
                    variant="standard"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Endereço"
                    fullWidth
                    variant="standard"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Bairro"
                    fullWidth
                    variant="standard"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Número"
                    fullWidth
                    variant="standard"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>            
            <Grid item xs={12}>
                <TextField
                    label="Cidade"
                    fullWidth
                    variant="standard"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="UF"
                    fullWidth
                    variant="standard"
                    value={uf}
                    onChange={(e) => setUF(e.target.value)}
                    InputProps={{
                      readOnly: type === 'ver',
                    }}
                />
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelar} color="secondary">
          {type === 'ver' ? 'Fechar' : 'Cancelar'}
        </Button>
        {type !== 'ver' && (
          <Button onClick={handleCadastrarEditar} color="primary">
            {type === 'cadastro' ? 'Cadastrar' : 'Salvar Edições'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CadastroInstituicao;
