import React,{ useState } from 'react';
import Cookies from 'js-cookie';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [admin, setAdmin] = React.useState(null);
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
  const [imageUrls, setImageUrls] = React.useState([]);
  const [imageUrlsVerified, setImageUrlsVerified] = React.useState(false);
  const [showExpMessage, setShowExpMessage] = useState(false);


  const signInUser = (userData) => {
    setUser(userData);
    setUserLoggedIn(true);
    Cookies.set('user', JSON.stringify(userData));
  };

  const refreshUser = (userData) =>{
    const data = {
      "idToken": userData.idToken,
      "refreshToken": userData.refreshToken,
      "photoExpiration": user.photoExpiration
    }
    setUser(data);
    setUserLoggedIn(true);
    Cookies.set('user', JSON.stringify(data));
  };

  const refreshAdmin = (userData) =>{
    const data = {
      "idToken": userData.idToken,
      "refreshToken": userData.refreshToken,
      "isSuperAdmin": admin.isSuperAdmin
    }
    setAdmin(data);
    setAdminLoggedIn(true);
    Cookies.set('admin', JSON.stringify(data));
  };

  const signOutUser = () => {
    setUser(null);
    setUserLoggedIn(false);
    Cookies.remove('user');
    window.location.reload(true);
  };

  const signInAdmin = (adminData) => {
    setAdmin(adminData);
    setAdminLoggedIn(true);
    Cookies.set('admin', JSON.stringify(adminData));
  };

  const signOutAdmin = () => {
    setAdmin(null);
    setAdminLoggedIn(false);
    Cookies.remove('admin');
    window.location.reload(true);
  };

  useState(() => {
    const userCookiesJson = Cookies.get('user');
    const userCookies = (userCookiesJson !== undefined ? JSON.parse(userCookiesJson) : null);
    const adminCookiesJson = Cookies.get('admin');
    const adminCookies = (adminCookiesJson !== undefined ? JSON.parse(adminCookiesJson) : null);

    if (userCookies) {
      setUser(userCookies);
      setUserLoggedIn(true);
    }

    if (adminCookies) {
      setAdmin(adminCookies);
      setAdminLoggedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        admin,
        userLoggedIn,
        adminLoggedIn,
        imageUrls,
        imageUrlsVerified,
        showExpMessage,
        setShowExpMessage,
        setImageUrlsVerified,
        setImageUrls,
        signInUser,
        signOutUser,
        signInAdmin,
        signOutAdmin,
        refreshUser,
        refreshAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };