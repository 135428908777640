import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';
import axios from 'axios';
import { useAdminData } from './adminContext';

const FormFuncionarios = ({ open, handleClose, editable }) => {
  const [formData, setFormData] = useState({
    nome: '',
    cpf: '',
    email: '',
    departamento: '',
    senha: ''
  });
  const { admin } = React.useContext(AuthContext);
  const {actionTrigger, setActionTrigger} = useAdminData();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    axios.post(baseUrl + 'admins/register',{
        nome: formData.nome,
        cpf: formData.cpf,
        email: formData.email,
        departamento: formData.departamento,
        password: formData.senha
      }, {
        headers:{
          "Authorization": "Bearer "+admin.idToken
        }
      })
        .then(response => {
          alert("Funcionário cadastrado com sucesso!");
          setActionTrigger(!actionTrigger);
        })
        .catch(error => {
          alert('Erro:', error);
        });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Formulário de Funcionários</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          margin="dense"
          id="nome"
          name="nome"
          label="Nome"
          type="text"
          fullWidth
          value={formData.nome}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          margin="dense"
          id="cpf"
          name="cpf"
          label="CPF"
          type="text"
          fullWidth
          value={formData.cpf}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          margin="dense"
          id="departamento"
          name="departamento"
          label="Departamento"
          type="text"
          fullWidth
          value={formData.departamento}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          margin="dense"
          id="senha"
          name="senha"
          label="Senha"
          type="text"
          fullWidth
          value={formData.senha}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        {editable && (
          <Button onClick={handleSubmit} color="primary">
            Salvar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormFuncionarios;
