import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { AuthContext } from '../authContext';
import axios from 'axios';
import { baseUrl } from '../../App';

const CriarTurmaDialog = ({ open, handleClose, handleSave, instituicaoSelecionada }) => {
  const [curso, setCurso] = useState('');
  const [contrato, setContrato] = useState('');
  const [dataFormatura, setDataFormatura] = useState('');
  const { admin } = React.useContext(AuthContext);

  const handleCursoChange = (event) => {
    setCurso(event.target.value);
  };

  const handleContratoChange = (event) => {
    setContrato(event.target.value);
  };

  const handleDataFormaturaChange = (event) => {
    setDataFormatura(event.target.value);
  };

  const handleSaveClick = () => {
    axios.post(baseUrl + 'admins/create-class',{
      nro_contrato: contrato,
      curso: curso,
      data_formatura: dataFormatura,
      id_instituicao: instituicaoSelecionada
    }, {
      headers:{
        "Authorization": "Bearer "+admin.idToken
      }
    })
      .then(response => {
        alert("Turma adicionada com sucesso!");
      })
      .catch(error => {
        alert('Erro:', error);
      });
    setContrato('');
    setCurso('');
    setDataFormatura('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Criar Turma</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="contrato"
          label="Número do Contrato"
          type="text"
          fullWidth
          value={contrato}
          onChange={handleContratoChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="curso"
          label="Nome do Curso"
          type="text"
          fullWidth
          value={curso}
          onChange={handleCursoChange}
        />
        <TextField
          margin="dense"
          id="data-formatura"
          label="Data de Formatura"
          type="date"
          fullWidth
          value={dataFormatura}
          onChange={handleDataFormaturaChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSaveClick} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CriarTurmaDialog;
