import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { AuthContext } from './authContext';
import { baseUrl } from '../App';
import Image from '../static/bgLogin.jpg';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://flashfotosformaturas.com.br/index.html">
          Flash Fotos Formaturas
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }


const defaultTheme = createTheme();

export default function Login(type) {

  const { signInUser, signInAdmin } = React.useContext(AuthContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (type.type === "admin") {
      axios.post(
        baseUrl + 'admins/login', {
          "username": data.get('username'),
          "password": data.get('password')
        }
      ).then(function (response) {
        const data = {
          "idToken": response.data.user.idToken,
          "refreshToken": response.data.user.refreshToken,
          "isSuperAdmin": response.data.superAdmin
        }
        signInAdmin(data);
      }).catch(function (error) {
        alert("Verifique suas credenciais e tente novamente!", error);
      });
    }else if (type.type === "user") {
      axios.post(
        baseUrl + 'users/login', {
          "username": data.get('username'),
          "password": data.get('password')
        }
      ).then(function (response) {
        const data = {
          "idToken": response.data.user.idToken,
          "refreshToken": response.data.user.refreshToken,
          "photoExpiration": response.data.user.photoExpiration
        }
        signInUser(data);
      }).catch(function (error) {
        alert("Verifique suas credenciais e tente novamente!", error);
      });
    }
  };

  function handleType(){
    if (type.type === "admin") {
      return "Entrar no painel administrativo"
    }
    else{
      return "Entrar na área do formando"
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {handleType()}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuário"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}