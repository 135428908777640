import React from 'react';
import AppRoutes from "./routes";

const App = () => {

  return (
    <AppRoutes/>
  );
};

export default App;
export const baseUrl = 'https://api.flashfotosformaturas.com.br/';