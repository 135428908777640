import React, { useState } from 'react';
import { useUploadContext } from './uploadContext';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';

const DropzoneComponent = ({ userId, username, open, onClose }) => {
  const { handleUpload } = useUploadContext();
  const [files, setFiles] = useState([]);
  const [lowResFiles, setLowResFiles] = useState([])


  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]); // Adiciona os arquivos selecionados à lista de arquivos

  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1); // Remove o arquivo com o índice especificado
    setFiles(updatedFiles); // Atualiza a lista de arquivos
  };

  const handleUploadClick = () => {
    handleUpload(files, userId, username, "high_resolution"); // Inicia o upload quando o botão "Iniciar Upload" é clicado
    alert("Upload iniciado! Verifique o progresso na barra de uploads, no canto inferior.");
    setFiles([]); // Limpa a lista de arquivos
    onClose(); // Fecha o dialog
  };

  const handleClose = () => {
    setFiles([]); // Limpa a lista de arquivos
    onClose(); // Fecha o dialog sem iniciar o upload
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Selecionar Arquivos</DialogTitle>
      <DialogContent dividers>
        <div {...getRootProps()} style={{ textAlign: 'center', padding: '40px', border: '2px dashed #aaa', borderRadius: '8px', cursor: 'pointer', marginBottom: '20px' }}>
          <input {...getInputProps()} />
          <Typography variant="body1" color="textSecondary">Arraste e solte os arquivos aqui ou clique para selecionar</Typography>
        </div>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Total de arquivos selecionados: {files.length}
        </Typography>
        <List style={{ maxHeight: '300px', overflow: 'auto' }}>
          {files.map((file, index) => (
            <ListItem key={index}>
              {file.type.startsWith('image/') && (
                <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxHeight: '50px', maxWidth: '50px', marginRight: '10px' }} />
              )}
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => removeFile(index)}>
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUploadClick} variant="contained" color="primary">
          Iniciar Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DropzoneComponent;



