import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';

const UploadContext = createContext();

export const useUploadContext = () => {
  return useContext(UploadContext);
};

export const UploadProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const { admin } = useContext(AuthContext);
  const debug = false;

  const uploadFilePart = async (file, partIndex, numParts, url, token, filename, userId, username, upload_type) => {
    const total_size = file.size;
    const partSize = 5 * 1024 * 1024;
    const start = partIndex * partSize;
    const end = Math.min(start + partSize, file.size);

    const formData = new FormData();
    formData.append('chunk', partIndex);
    formData.append('chunks', numParts);
    formData.append('filename', filename);
    formData.append('upload_type', upload_type);
    formData.append('username', userId); // Inclui o nome do usuário na requisição
    formData.append('file', file.slice(start, end));

    const config = {
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / total_size) * 100);
        const prevTotalProgress = uploadProgress[username]?.[filename]?.total_progress ?? 0;
        const prevUploadedSize = uploadProgress[username]?.[filename]?.uploaded_size ?? 0;
        const newTotalProgress = progress  + prevTotalProgress;
        const newUploadedSize = progressEvent.loaded + prevUploadedSize;
    
        setUploadProgress(prevUploadProgress => ({
          ...prevUploadProgress,
          [username]: {
            ...prevUploadProgress[username],
            [filename]: {
              ...prevUploadProgress[username][filename],
              total_progress: newTotalProgress,
              total_size: total_size,
              uploaded_size: newUploadedSize
              // Mantém os outros campos inalterados
            }
          }
        }));
      },
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };

    try {
      const response = await axios.post(url, formData, config);
      
      // Atualiza o progresso de upload
      setUploadProgress(prevProgress => {
        const userProgress = prevProgress[username] || {};
        const fileProgress = userProgress[filename] || {};
        const partProgress = fileProgress.parts ? { ...fileProgress.parts } : {};
      
        return {
          ...prevProgress,
          [username]: {
            ...userProgress,
            [filename]: {
              ...fileProgress,
              parts: {
                ...partProgress,
                [partIndex]: true
              },
              failed: false, // Define o valor de 'failed' como false
              total_size: total_size
            }
          }
        };
      });

      return true;

    } catch (error) {
        if (debug) {
            console.error(`Erro ao enviar a parte ${partIndex + 1}/${numParts} do arquivo ${filename} para o usuário ${username}:`, error);
        }

        return false;
    }
  };

  const uploadFileInParts = async (file, url, token, filename, userId, username, upload_type) => {
    const numParts = Math.ceil(file.size / (5 * 1024 * 1024));

    // Inicializa o progresso de upload para o arquivo do usuário
    setUploadProgress(prevProgress => ({
      ...prevProgress,
      [username]: {
        ...(prevProgress[username] ? prevProgress[username] : {}),
        [filename]: {parts: Array(numParts).fill(false), failed:false, total_progress:0, total_size:file.size, uploaded_size:0}
      }
    }));

    for (let i = 0; i < numParts; i++) {
      if (!uploadProgress[username]?.[filename]?.failed) {
        let retries = 0;
        let uploaded = false;
        while (retries < 5 && !uploaded) {
          uploaded = await uploadFilePart(file, i, numParts, url, token, filename, userId, username, upload_type);
          retries++;
        }
        if (uploaded === false) {
          setUploadProgress(prevProgress => {
            const userProgress = prevProgress[username] || {};
            const fileProgress = userProgress[filename] || {};
            const partProgress = fileProgress.parts ? { ...fileProgress.parts } : {};
          
            return {
              ...prevProgress,
              [username]: {
                ...userProgress,
                [filename]: {
                  ...fileProgress,
                  parts: { ...partProgress }, // Mantém as partes existentes
                  failed: true // Define o valor de 'failed' como true
                }
              }
            };
          });
        } 
        
      }
    }
  };

  const handleUpload = async (files, userId, username, upload_type) => {
    const config = {
        headers: {
          'Authorization': `Bearer ${admin.idToken}`
        }
      };
    const response = await axios.post(`${baseUrl}admins/set-expiration`, {'username':userId}, config);
    const uploadPromises = files.map(file =>
      uploadFileInParts(file, `${baseUrl}admins/upload-image`, admin.idToken, file.name, userId, username, upload_type)
    );
    await Promise.all(uploadPromises);
    setSelectedFiles([]);
  };

  return (
    <UploadContext.Provider value={{ selectedFiles, setSelectedFiles, uploadProgress, handleUpload, setUploadProgress }}>
      {children}
    </UploadContext.Provider>
  );
};
