import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AuthContext } from '../authContext';
import axios from 'axios';
import { baseUrl } from '../../App';



const TurmaDialog = ({ open, onClose, turmaData }) => {
  const { id, instituicao, curso, contrato, dataFormatura } = turmaData;
  const [linkGerado, setLinkGerado] = useState('');
  const [isGetting, setIsGetting] = useState(false);
  const { admin } = useContext(AuthContext);

  const handleGerarLink = () => {
    
    if (isGetting) {
      return;
    }
    
    setIsGetting(true);
      
    axios.get(baseUrl + 'admins/get-register-link?idTurma=' + id, {
      headers:{
        "Authorization": "Bearer "+admin.idToken
      }
    })
      .then(response => {
        setLinkGerado(`https://formandos.flashfotosformaturas.com.br/registrar-aluno/${response.data.token}`);
      })
      .catch(error => {
        if (error?.response?.data?.error) {
          alert('Erro:', error);
        } 
        setLinkGerado('');
      }).finally(() => {
        setIsGetting(false);
      });
    
  };

  const handleCloseLinkDialog = () => {
    setLinkGerado('');
  };

  const handleCopyToClipboard = (variavelParaCopiar) => {
    navigator.clipboard.writeText(variavelParaCopiar)
    .then(() => {
      alert('Valor copiado para a área de transferência!');
    })
    .catch((error) => {
      console.error('Erro ao copiar valor para a área de transferência:', error);
      alert('Erro ao copiar valor para a área de transferência.');
    });
    
  };

  const getDateString = (dataString) => {

    let dataFormatada;
    if (dataString !== null) {
      const data = new Date(dataString);

      // Formatar a data para "DD/MM/YYYY"
      const dia = data.getUTCDate().toString().padStart(2, '0');
      const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
      const ano = data.getFullYear();

      dataFormatada = `${dia}/${mes}/${ano}`;
    }else{
      dataFormatada = '';
    }
    
    return dataFormatada;
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle><b>Dados da Turma</b></DialogTitle>
        <DialogContent>
          <p><b>Instituição:</b> {instituicao}</p>
          <p><b>Curso:</b> {curso}</p>
          <p><b>Número do Contrato:</b> {contrato}</p>
          <p><b>Data de Formatura:</b> {getDateString(dataFormatura)}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant='contained'>
            Fechar
          </Button>
          <Button onClick={handleGerarLink} color="primary" variant='contained'>
            Gerar link de formandos
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!linkGerado} onClose={handleCloseLinkDialog}>
        <DialogTitle>Link Gerado</DialogTitle>
        <DialogContent>
          <a href={linkGerado} target="_blank" rel="noopener noreferrer">{linkGerado}</a>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCopyToClipboard(linkGerado)} color="primary">
            Copiar Link
          </Button>
          <Button onClick={handleCloseLinkDialog} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TurmaDialog;
