import React from 'react';
import { Modal, CircularProgress } from '@mui/material';

const overlayStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Cobertura de fundo semi-transparente
};

const LoadingOverlay = ({ open }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal-description"
    >
      <div style={overlayStyles}>
        <CircularProgress />
      </div>
    </Modal>
  );
};

export default LoadingOverlay;
