import React, { useState, useRef, useContext, useEffect } from 'react';
import { Grid, Card, CardMedia, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';
import axios from 'axios';

const GalleryDialog = ({ open, handleClose, user_id }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const timeoutRef = useRef(null);
    const { admin, imageUrlsVerified, setImageUrlsVerified} = useContext(AuthContext);
    const [images, setImageUrls] = useState([]);
    const [isGetting, setIsGetting] = useState(false);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {

        const getImages = () => {
    
          if (isGetting) {
            return;
          }
        
          setIsGetting(true);
          
          axios.get(baseUrl + 'admins/image_urls?userId=' + user_id, {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          })
            .then(response => {
              setImageUrls(response.data.images);
              setImageUrlsVerified(true);
            })
            .catch(error => {
              if (error?.response?.data?.error) {
                alert('Erro:', error);
              } else if(error?.response?.status === 404) {
                setImageUrlsVerified(false);
              }
              setImageUrls([]);
            }).finally(() => {
              setIsGetting(false);
            });
        };
        if ((images.length === 0) && (open)) {
          getImages();
        }
      }, [open, deleteTrigger]); // Executar apenas uma vez, ao montar o componente

    const handleImageClick = (imageUrl) => {
        if (showCheckboxes) {
            handleCheckboxChange(imageUrl);
        } else {
            timeoutRef.current = setTimeout(() => {
                setShowCheckboxes(true);
                handleCheckboxChange(imageUrl);
            }, 1000);
        }
    };

    const handleImageRelease = () => {
        clearTimeout(timeoutRef.current);
        if (selectedImages.length === 0){
            setShowCheckboxes(false);
        }
    };

    const handleCheckboxChange = (imageId) => {
        if (selectedImages.includes(imageId)) {
            setSelectedImages(prevSelected => prevSelected.filter(img => img !== imageId));
        } else {
            setSelectedImages(prevSelected => [...prevSelected, imageId]);
        }
    };

    const handleDeleteImages = async () => {
        setConfirmOpen(false);
        try {
            const response = await axios.post(
                baseUrl + 'admins/delete-image',
                { "files": selectedImages, "userId":user_id },
                {
                    headers: {
                        'Authorization': `Bearer ${admin.idToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setSelectedImages([]);
            setShowCheckboxes(false);
            setDeleteTrigger(!deleteTrigger);
            setImageUrls(images.filter(item => !selectedImages.includes(item.id)));
            alert("Imagens deletadas com sucesso!");
        } catch (error) {
            alert('Erro ao deletar arquivos:', error);
        }
        
    };

    const handleCancelSelection = () => {
        setSelectedImages([]);
        setShowCheckboxes(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Galeria de Imagens</DialogTitle>
            <DialogContent>
                {imageUrlsVerified?(
                    <Grid container spacing={2}>
                        {images.map(image => (
                            <Grid key={image.id} item xs={12} sm={6} md={4}>
                                <Card 
                                    style={{ border: selectedImages.includes(image.id) ? '2px solid blue' : 'none' }}
                                    onMouseDown={() => handleImageClick(image.id)} 
                                    onMouseUp={handleImageRelease}
                                >
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={`${baseUrl}admins${image.url}`}
                                        alt={`Imagem ${image.id}`}
                                    />
                                    {showCheckboxes && (
                                        <CardActions>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={selectedImages.includes(image.id)} 
                                                        onChange={() => handleCheckboxChange(image.id)}
                                                    />
                                                }
                                                label="Selecionar"
                                            />
                                        </CardActions>
                                    )}
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ):(
                    <Typography variant="body1">Esse usuário não possui fotos. Faça o upload das fotos na seção de upload para poder gerenciar!</Typography>
                )}
                
            </DialogContent>
            {selectedImages.length > 0 && (
                <DialogActions>
                    <Button variant="contained" onClick={handleCancelSelection}>Cancelar seleção</Button>
                    <Button variant="contained" onClick={() => {setConfirmOpen(true)}}>Excluir Selecionadas</Button>
                </DialogActions>
            )}
            <div>
                <Dialog
                    open={confirmOpen}
                    onClose={() => {setConfirmOpen(false)}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmação necessária"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza de que deseja deletar as imagens?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {setConfirmOpen(false)}} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteImages} color="primary" autoFocus>
                        Confirmar
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Dialog>
    );
};

export default GalleryDialog;



