import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Grid, Paper, Box, useMediaQuery, useTheme, IconButton, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { AuthContext } from '../authContext';
import { baseUrl } from '../../App';

const DownloadDialog = ({ isOpen }) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Preparando seu download. Isso pode demorar alguns minutos, aguarde!
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const GalleryComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const { user, imageUrls, setImageUrls, imageUrlsVerified, setImageUrlsVerified, showExpMessage, setShowExpMessage } = React.useContext(AuthContext);
  var date = new Date(user.photoExpiration*1000);
  var localTime = date.getTime();
  var localDate = new Date(localTime);

  const [isGetting, setIsGetting] = useState(false);

  React.useEffect(() => {

    const getImages = () => {

      if (isGetting) {
        return;
      }
    
      setIsGetting(true);
      
      axios.get(baseUrl + 'users/image_urls', {
        headers:{
          "Authorization": "Bearer "+user.idToken
        }
      })
        .then(response => {
          setImageUrls(response.data.images);
          setShowExpMessage(true);
          setImageUrlsVerified(true);
        })
        .catch(error => {
          if (error?.response?.data?.error) {
            alert('Erro:', error);
          } else if(error?.response?.status === 404) {
            setShowExpMessage(false);
            setImageUrlsVerified(true);
          }
          
        }).finally(() => {
          setIsGetting(false);
        });
    };

    if (imageUrls.length === 0 && !imageUrlsVerified) {
      getImages();
    }
    
  }, []); // Executar apenas uma vez, ao montar o componente

  const handleDownloadClick = () => {
    setIsDownloadOpen(true);
    axios({
      method: 'get',
      url: baseUrl + 'users/download_all',
      timeout: 15 * 60 * 1000, // Timeout de 15 minutos
      responseType: "blob",
      headers:{
        "Authorization": "Bearer "+user.idToken
      }
    })
      .then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'fotos.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setIsDownloadOpen(false);
      })
      .catch(error => {
        setIsDownloadOpen(false);
        console.error('Erro ao baixar arquivo:', error);
      });
  };

  const openModal = (index) => {
    setCurrentImage(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(0);
    setModalOpen(false);
  };

  const downloadImage = () => {
    const fileName = imageUrls[currentImage].split("/")[2];
    const url = `${baseUrl}users/download/${fileName}`;
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  const handlePrevious = () => {
    setCurrentImage((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1));
  };

  const handleNext = () => {
    setCurrentImage((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <React.Fragment>
    <Typography style={{color:'red'}}>
      {showExpMessage ? (`Atenção! Você tem até ${localDate.toLocaleString().replace(',', ' às')} para baixar as suas fotos.`) : ("Você não possui imagens para visualizar!")}
    </Typography>
    {showExpMessage ? (
      <Button
        variant="contained"
        style={{
          backgroundColor: '#2196F3',  // Cor azul
          color: '#ffffff',            // Cor branca
          margin: '10px 0',           // Margem de 10 pixels acima e abaixo
        }}
        onClick={handleDownloadClick}
      >
        Baixar todas as imagens
      </Button>
    ):(
      null
    )}
    <Grid container spacing={2}>
      {imageUrls.map((imageUrl, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', cursor: 'pointer' }} onClick={() => openModal(index)}>
            <img src={baseUrl + "users" + imageUrl } alt={` ${index + 1}`} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </Paper>
        </Grid>
      ))}

        <Dialog
        open={modalOpen}
        onClose={closeModal}
        fullScreen={isMobile}
        PaperProps={{
            style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            margin: 0,
            },
        }}
        >
        <DialogContent
            sx={{
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            }}
        >
            <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            >
            <img
                src={baseUrl + "users" + imageUrls[currentImage]}
                alt={` ${currentImage + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
            <Box
                sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                padding: '20px',
                overflow: 'hidden', // Remova a barra de rolagem
                }}
            >
                <IconButton onClick={downloadImage} variant="outlined" style={{backgroundColor: '#ffff'}}>
                <DownloadIcon />
                </IconButton>
                <IconButton onClick={closeModal} variant="outlined" style={{backgroundColor: '#ffff', marginLeft: '20%'}}>
                <CloseIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                position: 'absolute',
                height: '80%',
                mt: '15%',
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px',
                overflow: 'hidden', // Remova a barra de rolagem
                }}
            >
                <IconButton onClick={handlePrevious} variant="outlined" >
                <ArrowBackIosIcon />
                </IconButton>
                <IconButton onClick={handleNext} variant="outlined" >
                <ArrowForwardIosIcon />
                </IconButton>
            </Box>
            </Box>
        </DialogContent>
        </Dialog>
    </Grid>
    <DownloadDialog isOpen={isDownloadOpen} />
    </React.Fragment>
  );
};

export default GalleryComponent;
