import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Input, FormControl, InputLabel, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { useData } from './DataContext';


const CelMaskCustom = React.forwardRef(function CelMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
  
CelMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CepMaskCustom = React.forwardRef(function CepMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
    
  CepMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
const UfMaskCustom = React.forwardRef(function UfMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="**"
      definitions={{
        '*': /[A-Za-z]/,
      }}
      inputRef={ref}
      onAccept={(value) => {
        const uppercaseValue = value.toUpperCase(); // Converter o valor para maiúsculas
        onChange({ target: { name: props.name, value: uppercaseValue } }); // Chamar onChange com o valor convertido
      }}
      overwrite
    />
  );
});
  
UfMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Address() {

    const { formData, updateFormData } = useData();

    const numberStyles = {
      input: {
        '-webkit-appearance': 'textfield', // Remover as setinhas no Safari
        '-moz-appearance': 'textfield', // Remover as setinhas no Firefox
        appearance: 'textfield' // Remover as setinhas no Chrome e Edge
      }
    };

    const handleRespostaChange = (event) => {
      const valor = event.target.value;
      // Se "Sim" for selecionado, definimos a variável respostaSim como true
      if (valor === 'sim') {
        updateFormData({moraComPais: true})
      } else {
        updateFormData({moraComPais: false})
      }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData({ [name]: value });
        if ((value.length > 0) || (value !== '')){
            updateFormData({ [name+'_err']: false });
        }else{
            updateFormData({ [name+'_err']: true });
        }
    };
    
    const handleCep = (event) => {
        event.preventDefault();
        fetch(`https://viacep.com.br/ws/${formData.cep}/json/`)
        .then(response => {
            if (!response.ok) {
                alert('Erro ao consultar o CEP')
            }
            return response.json();
          })
          .then(data => {
            if (data && !data.erro) {
              updateFormData({endereco: data.logradouro, endereco_err: false})
              updateFormData({bairro: data.bairro, bairro_err: false})
              updateFormData({cidade: data.localidade, cidade_err: false})
              updateFormData({uf: data.uf, uf_err: false})
            } else {
                alert('CEP não encontrado ou inválido')
            }
          });
        }

    const handleCepPais = (event) => {
        event.preventDefault();
        fetch(`https://viacep.com.br/ws/${formData.cepPais}/json/`)
        .then(response => {
            if (!response.ok) {
                alert('Erro ao consultar o CEP')
            }
            return response.json();
          })
          .then(data => {
            if (data && !data.erro) {
                updateFormData({enderecoPais: data.logradouro, enderecoPais_err: false})
                updateFormData({bairroPais: data.bairro, bairroPais_err: false})
                updateFormData({cidadePais: data.localidade, cidadePais_err: false})
                updateFormData({ufPais: data.uf, ufPais_err: false})
            } else {
                alert('CEP não encontrado ou inválido')
            }
          });
        }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom mb={3}>
                Endereço e Contato
            </Typography>
            <Grid container spacing={3} gutterBottom>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error = {formData.cep_err || false}>
                        <InputLabel htmlFor="cep-value" variant='standard' required>CEP</InputLabel>
                        <Input
                            required
                            id="cep-value"
                            name="cep"
                            value={formData.cep || ''}
                            onChange={handleChange}
                            onBlur={handleCep}
                            fullWidth
                            variant="standard"
                            inputComponent={CepMaskCustom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        required
                        id="endereco"
                        name="endereco"
                        label="Endereço"
                        value={formData.endereco || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-endereco"
                        variant="standard"
                        error = {formData.endereco_err || false}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        id="numero"
                        name="numero"
                        label="Número"
                        value={formData.numero || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-numero"
                        variant="standard"
                        type="number"
                        inputProps={{
                            inputMode: 'numeric',
                            style: numberStyles.input
                          }}
                        error = {formData.numero_err || false}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                        value={formData.complemento || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-complemento"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        required
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                        value={formData.bairro || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-bairro"
                        variant="standard"
                        error = {formData.bairro_err || false}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        required
                        id="cidade"
                        name="cidade"
                        label="Cidade"
                        value={formData.cidade || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-cidade"
                        variant="standard"
                        error = {formData.cidade_err || false}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth error = {formData.uf_err || false}>
                        <InputLabel htmlFor="uf-value" variant='standard' required>UF</InputLabel>
                        <Input
                            required
                            id="uf"
                            name="uf"
                            value={formData.uf || ''}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                            inputComponent={UfMaskCustom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error = {formData.celular_err || false}>
                        <InputLabel htmlFor="celular-value" variant='standard' required>Celular/WhatsApp</InputLabel>
                        <Input
                            required
                            id="celular-value"
                            name="celular"
                            value={formData.celular || ''}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                            inputComponent={CelMaskCustom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="celular2-value" variant='standard'>Celular 2</InputLabel>
                        <Input
                            id="celular2-value"
                            name="celular2"
                            value={formData.celular2 || ''}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                            inputComponent={CelMaskCustom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Você mora com seus pais/familiares?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleRespostaChange}
                            value = {formData.moraComPais === false ? 'nao' : 'sim'}
                        >
                            <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                            <FormControlLabel value="nao" control={<Radio />} label="Não" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {(!formData.moraComPais && formData.moraComPais !== undefined) && (
            <React.Fragment>
                <Typography variant="h6" gutterBottom mt={3} mb={3}>
                Endereço e Contato dos Pais/Outros endereços de contato
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error = {formData.cepPais_err || false}>
                            <InputLabel htmlFor="cep-value" variant='standard' required>CEP</InputLabel>
                            <Input
                                required
                                id="cep-value-pais"
                                name="cepPais"
                                value={formData.cepPais || ''}
                                onChange={handleChange}
                                onBlur={handleCepPais}
                                fullWidth
                                variant="standard"
                                inputComponent={CepMaskCustom}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            id="endereco-pais"
                            name="enderecoPais"
                            label="Endereço"
                            value={formData.enderecoPais || ''}
                            onChange={handleChange}
                            fullWidth
                            autoComplete="valor-endereco-pais"
                            variant="standard"
                            error = {formData.enderecoPais_err || false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            id="numero-pais"
                            name="numeroPais"
                            label="Número"
                            value={formData.numeroPais || ''}
                            onChange={handleChange}
                            fullWidth
                            autoComplete="valor-numero-pais"
                            variant="standard"
                            type="number"
                            inputProps={{
                                inputMode: 'numeric',
                                style: numberStyles.input
                            }}
                            error = {formData.numeroPais_err || false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="complemento-pais"
                            name="complementoPais"
                            label="Complemento"
                            value={formData.complementoPais || ''}
                            onChange={handleChange}
                            fullWidth
                            autoComplete="valor-complemento-pais"
                            variant="standard"
                            error = {formData.complementoPais_err || false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            required
                            id="bairro-pais"
                            name="bairroPais"
                            label="Bairro"
                            value={formData.bairroPais || ''}
                            onChange={handleChange}
                            fullWidth
                            autoComplete="valor-bairro-pais"
                            variant="standard"
                            error = {formData.bairroPais_err || false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            required
                            id="cidadePais"
                            name="cidadePais"
                            label="Cidade"
                            value={formData.cidadePais || ''}
                            onChange={handleChange}
                            fullWidth
                            autoComplete="valor-cidade-pais"
                            variant="standard"
                            error = {formData.cidadePais_err || false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                    <FormControl fullWidth error = {formData.ufPais_err || false}>
                        <InputLabel htmlFor="ufPais-value" variant='standard' required>UF</InputLabel>
                        <Input
                            required
                            id="uf-pais"
                            name="ufPais"
                            value={formData.ufPais || ''}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                            inputComponent={UfMaskCustom}
                        />
                    </FormControl>
                </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error = {formData.celularPais_err || false}>
                            <InputLabel htmlFor="celular-pais-value" variant='standard' required>Celular/WhatsApp</InputLabel>
                            <Input
                                required
                                id="celular-pais-value"
                                name="celularPais"
                                value={formData.celularPais || ''}
                                onChange={handleChange}
                                fullWidth
                                variant="standard"
                                inputComponent={CelMaskCustom}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </React.Fragment>
            )
        }
        </React.Fragment>
    );
}