import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BackupIcon from '@mui/icons-material/Backup';


export const mainListItems = (paginaSelecionada, handleItemClick, isSuperAdmin) => (
  <React.Fragment>
    <ListItemButton selected={paginaSelecionada === 'Turmas'} onClick={() => handleItemClick('Turmas')}>
      <ListItemIcon>
        <SchoolIcon />
      </ListItemIcon>
      <ListItemText primary="Turmas" />
    </ListItemButton>
    <ListItemButton selected={paginaSelecionada === 'Instituicao'} onClick={() => handleItemClick('Instituicao')}>
      <ListItemIcon>
        <AccountBalanceIcon />
      </ListItemIcon>
      <ListItemText primary="Instituições" />
    </ListItemButton>
    {isSuperAdmin ? (
      <ListItemButton selected={paginaSelecionada === 'Funcionario'} onClick={() => handleItemClick('Funcionario')}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Funcionários" />
      </ListItemButton>
    ):(
      null
    )}
  </React.Fragment>
);
