import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { mainListItems } from './listItems';
import { Hidden } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Paper from '@mui/material/Paper';
import Gallery from './galeria';
import Boletos from './boletos';
import axios from 'axios';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';
import Login from '../login';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://flashfotosformaturas.com.br/index.html">
          Flash Fotos Formaturas
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AppAluno() {
  const [open, setOpen] = React.useState(false);
  const [paginaSelecionada, setPaginaSelecionada] = React.useState('Galeria');
  const { userLoggedIn, signOutUser, refreshUser, user } = React.useContext(AuthContext);
  let [isRefreshing, setIsRefreshing] = React.useState(false);

  React.useEffect(() => {

    const refreshToken = async () => {

      if (isRefreshing) {
        return;
      }
    
      setIsRefreshing(true);
      
      let suffix = '';
      let refresh_token = '';
      if (userLoggedIn) {
        suffix = 'users/refresh';
        refresh_token = user.refreshToken;
        try {
          // Faça a solicitação para obter um novo token de atualização
          const response = await axios.post(`${baseUrl}${suffix}`, {
            refreshToken: refresh_token
          });
  
          // Atualize o token de acesso com o novo token obtido
          const data = {
            "idToken": response.data.user.idToken,
            "refreshToken": response.data.user.refreshToken
          }
          if (userLoggedIn) {
            refreshUser(data);
          }
          
        } catch (error) {
          if (error?.response?.data?.message){
            alert('Erro ao solicitar o novo token de atualização:', error.response.data.message);
          }
          signOutUser();
        } finally{
          setIsRefreshing(false);
        }
      }
    };
    setInterval(refreshToken, 20*60*1000);
    refreshToken();

  }, [userLoggedIn]);

  function getPageContent(page) {
    if (page === 'Galeria'){
      return <Gallery />
    }
    if (page === 'Boletos'){
      return <Boletos />
    }
  }

  const handleItemClick = (pagina) => {
    setPaginaSelecionada(pagina);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    signOutUser();
  }

  return (
    <>
    {userLoggedIn ? (
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Hidden smDown implementation="css">
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Área do Formando
              </Typography>
              <IconButton color="inherit" onClick={handleLogout}>
                  <LogoutIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              {mainListItems(paginaSelecionada, handleItemClick)}
            </List>
          </Drawer>
          </Hidden>
          <Hidden smUp implementation='css'>
            <AppBar position="absolute" open={open} color='inherit' elevation={2}>
              <Toolbar
                sx={{
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="primary"
                  sx={{ flexGrow: 1 }}
                >
                  Área do Formando
                </Typography>
                <IconButton color="primary" onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={2}>
              <BottomNavigation
                showLabels
                value={paginaSelecionada}
                onChange={(event, newValue) => {
                  setPaginaSelecionada(newValue);
                }}
              >
                <BottomNavigationAction label="Galeria" icon={<PhotoLibraryIcon />} value="Galeria" />
                <BottomNavigationAction label="Boletos" icon={<RequestQuoteIcon />} value="Boletos" />
              </BottomNavigation>
            </Paper>
          </Hidden>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
          <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              {getPageContent(paginaSelecionada)}
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    ):(
      <Login type="user"/>
    )}
    
    </>
  );
}