import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, LinearProgress, IconButton, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUploadContext } from './uploadContext';

const UploadAccordion = () => {
  const { uploadProgress, setUploadProgress } = useUploadContext();
  

  const handleCloseAccordion = (user_id) => {
    setUploadProgress(current => {
      // 👇️ remove the salary key from an object
      const rest = {...current};
      delete rest[user_id];
      return rest;
    });
  };


  return (
    <div>
      <Typography variant="h6" style={{ marginBottom: '10px' }}><b>Progresso de uploads</b></Typography>
      {Object.keys(uploadProgress).length > 0 ? (
        Object.keys(uploadProgress).map((username, index) => (
          <Accordion key={index} slotProps={{ transition: { unmountOnExit: true } }}>
            <AccordionSummary expandIcon={calculateGlobalProgress(uploadProgress[username]) === 100 ? (null):(<ExpandMoreIcon />)} aria-controls={`panel-${index}-content`} id={`panel-${index}-header`}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">{username} {calculateGlobalProgress(uploadProgress[username]) === 100?(" - Upload Completo!"):(null)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <LinearProgress
                      variant="determinate"
                      value={calculateGlobalProgress(uploadProgress[username])}
                      style={{ marginTop: '15px' }}
                  />
                </Grid>
                {calculateGlobalProgress(uploadProgress[username]) === 100 ? (
                    setTimeout(handleCloseAccordion(username), 3000)
                    ):(
                    null
                  )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                {Object.keys(uploadProgress[username]).map((filename, idx) => (
                    (calculateProgress(uploadProgress[username][filename]) < 100)?(
                      <>
                        <div key={idx} style={{ marginBottom: '10px' }}>
                            <Typography variant="body2">{filename}</Typography>
                        </div>
                        <div>
                        {uploadProgress[username][filename].failed ? (
                            <Typography variant="body2"> - Upload Falhou</Typography>
                          ):(
                            <LinearProgress
                                  variant="determinate"
                                  value={uploadProgress[username][filename].total_progress}
                                  style={{ marginTop: '5px' }}
                              />
                        )}
                        </div>
                      </>
                    ):(
                        null
                    )
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography variant="body1">O progresso dos seus uploads aparecerá aqui!</Typography>
      )}
    </div>
  );
};

// Função auxiliar para calcular o progresso total
const calculateProgress = (fileProgress) => {
  if (!fileProgress) return 0;
  
  let numPartsCompleted = 0;
  const numParts = Object.keys(fileProgress.parts).length;
  
  for (let i = 0; i < numParts; i++) {
    if (fileProgress.parts[i]) {
      numPartsCompleted++;
    }
  }
  
  return (numPartsCompleted / numParts) * 100;
};

const calculateGlobalProgress = (userData) => {
  if (!userData) return 0;
  
  let numPartsCompleted = 0;
  let numParts = 0;
  for (let file in userData) {
    const numPartsFile = Object.keys(userData[file].parts).length;
    numParts += numPartsFile;
    for (let i = 0; i < numPartsFile; i++) {
      if (userData[file].parts[i]) {
        numPartsCompleted++;
      }
    }
  }
  
  return (numPartsCompleted/numParts)*100;
};

export default UploadAccordion;



