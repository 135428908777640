import React, { useState, useEffect } from 'react';
import { Button, Container, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, TextField, Grid } from '@mui/material';
import { PDFDocument, rgb, StandardFonts, degrees } from 'pdf-lib';
import CriarTurmaDialog from './criarturma';
import { AuthContext } from '../authContext';
import axios from 'axios';
import { baseUrl } from '../../App';
import { useAdminData } from './adminContext';
import TurmaDialog from './turmasDetail';
import UserProfileDialog from './gerenciarAluno';
import CreateUser from './criarFormando';
import { loadTinyYolov2Model } from 'face-api.js';

const Turma = () => {
  const [selectedInstituicao, setSelectedInstituicao] = useState('');
  const [selectedTurma, setSelectedTurma] = useState('');
  const [alunos, setAlunos] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [showTurmaDetails, setShowTurmaDetails] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const {instituicoes} = useAdminData();
  const { admin } = React.useContext(AuthContext);
  const [turmas, setTurmas] = useState([]);
  const [turma_data, setTurmaData] = useState({});
  const [userDetailOpen, setUserDetailOpen] = useState(false);
  const [userDetailId, setUserDetailId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showCreateUser, setShowCreateUser] = useState(false);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

  useEffect(() => {
    if (searchTerm !== ''){
      setFilteredData(alunos.filter(item =>
        item.id.toString() === searchTerm ||
        item.primeiroNome.toLowerCase().includes(searchTerm.toLowerCase())||
        item?.sobrenome?.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }else{
      setFilteredData(alunos);
    }
  }, [searchTerm, alunos]);

  useEffect(() => {
    if (selectedInstituicao) {
      setShowComponent(true);
    }
    const fetchTurmas = async () => {
      try {
        const response = await axios.get(baseUrl + 'admins/list-class?instituicao=' + selectedInstituicao, {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          })
        setTurmas(response.data.turmas);
      } catch (error) {
        alert('Erro ao buscar turmas:', error);
      }
    };
  
    // Chamada da função para buscar turmas
    if (selectedInstituicao) {
      fetchTurmas();
    }
    setAlunos([]);
  }, [selectedInstituicao, dialogOpen])

  // Função para carregar os alunos da turma selecionada (simulada)
  const carregarAlunos = (turma) => {
    const fetchFormandos = async () => {
      try {
        const response = await axios.get(baseUrl + 'admins/list-user?turma=' + turma, {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          })
        setAlunos(response.data.formandos);
        setFilteredData(response.data.formandos.sort((a, b) => {
          const concatenacaoA = (a.primeiroNome + a.sobrenome).toLowerCase();
          const concatenacaoB = (b.primeiroNome + b.sobrenome).toLowerCase();
          
          return concatenacaoA.localeCompare(concatenacaoB);
        }));
      } catch (error) {
        if (error.response.data.message === "Sem usuários registrados nessa turma!") {
          setAlunos([]);
          setFilteredData([]);
        }else{
          alert('Erro ao buscar formandos:', error.response.data.message);
        }
        
      }
    };
  
    // Chamada da função para buscar turmas
    fetchFormandos();
    setSearchTerm('');
  };

  const handleShowTurmaDetails = () => {
    const turma_selec = turmas.find(turma => turma.id === selectedTurma)
    const inst = instituicoes.find(instituicao => instituicao.id === turma_selec.id_instituicao)
    setTurmaData({
      id: turma_selec.id,
      instituicao: inst.nome,
      curso: turma_selec.curso,
      contrato: turma_selec.nro_contrato,
      dataFormatura: turma_selec.data_formatura
    });
    setShowTurmaDetails(true);
  };

  const handleCloseTurmaDetails = () => {
    setShowTurmaDetails(false);
  };

  // Função para lidar com a mudança da instituição selecionada
  const handleInstituicaoChange = (reg) => {
    setSelectedInstituicao(reg.value);
    setSelectedTurma('');
    setShowComponent(false); // Ocultar o componente de turmas ao mudar de instituição
  };


  // Função para lidar com o clique no botão "Criar turma"
  const handleCriarTurma = () => {
    setDialogOpen(true);
  };

  // Função para lidar com o fechamento do diálogo
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const getDateString = (dataString) => {

    let dataFormatada;
    if (dataString !== null){
      const data = new Date(dataString);

      // Formatar a data para "DD/MM/YYYY"
      const dia = data.getUTCDate().toString().padStart(2, '0');
      const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
      const ano = data.getFullYear();

      dataFormatada = `${dia}/${mes}/${ano}`;
    } else {
      dataFormatada = '';
    }
    
    return dataFormatada;
  };

  const getDateTimeString = (dataString) => {

    let dataFormatada;
    if (dataString !== null){
      const data = new Date(dataString);

      // Formatar a data para "DD/MM/YYYY"
      const dia = data.getUTCDate().toString().padStart(2, '0');
      const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
      const ano = data.getFullYear();
      const hora = data.getHours() + 3;
      const minuto = data.getMinutes();
      const segundo = data.getSeconds()

      dataFormatada = `${dia}/${mes}/${ano} - ${hora}:${minuto}:${segundo}`;
    } else {
      dataFormatada = '';
    }
    
    return dataFormatada;
  };

  const handleCloseUserDetails = () => {
    setUserDetailOpen(false);
    setUserDetailId('');
  };
  // Função para salvar os dados da turma
  const handleSaveTurma = (curso, dataFormatura, instituicaoId) => {
    // Aqui você pode adicionar a lógica para salvar os dados da turma
    const novaTurma = {
      id: `turma${turmas.length + 1}`,
      nome: curso,
      id_instituicao: instituicaoId,
      dataFormatura: dataFormatura
    };
    setTurmas([...turmas, novaTurma]); // Adicionando a nova turma apenas à lista de turmas da instituição selecionada
    setAlunos([]); // Limpando a lista de alunos para a nova turma
    setFilteredData([]);
    handleCloseDialog();
  };

  // Função para lidar com a mudança da turma selecionada
  const handleTurmaChange = (value) => {
    setSelectedTurma(value);
    carregarAlunos(value);
  };

  const handleDeleteFormando = async (user_id) => {
      const url = baseUrl + 'admins/delete-user';
      const data = { "id": user_id };
      const config = {
          headers: {
              'Authorization': `Bearer ${admin.idToken}`,
              'Content-Type': 'application/json'
          }
      };

      try {
          const response = await axios.post(url, data, config);
          alert("Usuário deletado!")
          setFilteredData(filteredData.filter(item => item.id !== user_id));
      } catch (error) {
          alert("Erro ao deletar o formando!")
      }
  };

  const embedImage = async (pdfDoc, imageBase64) => {
    const imageBytes = Uint8Array.from(atob(imageBase64), c => c.charCodeAt(0));
    const image = await pdfDoc.embedJpg(imageBytes);
    return image;
  };

  const fetchAlunoData = async (userId) => {
    if (userId !== '') {
      try {
        const response = await axios.get(`${baseUrl}admins/get-user?userId=${userId}`, {
          headers:{
            "Authorization": "Bearer "+admin.idToken
          }
        });
        return(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        alert("Erro ao buscar informações do formando ao gerar o relatório!");
        return null;
      }
    }
  };

  // Encontrar os dados na estrutura pelo id
  function findById(list, id) {
    return list.find(reg => reg.id === id);
  }

  // Baixar todas as fotos de perfil
  const downloadAllProfilePicture = async (listaAlunos) => {
    for (const aluno of listaAlunos) {
      const alunoData = await fetchAlunoData(aluno.id);
      if (!alunoData) continue;

      // Cria um link temporário para a imagem
      const link = document.createElement('a');
      link.href = `data:image/jpeg;base64, ${alunoData.profile_image}`;

      // Define o atributo "download" para forçar o download da imagem
      link.download = `${alunoData.pessoal?.primeiroNome}_${alunoData.pessoal?.id}.jpg`;

      // Simula um clique no link para iniciar o download
      document.body.appendChild(link);
      link.click();

      // Remove o link temporário
      document.body.removeChild(link);
    }
  };

  const generatePdf = async (instituicao, turma, listaAlunos) => {

    alert(`Iniciada a geração do relatório para a turma ${turma}`)
    // Cria um novo documento PDF
    const pdfDoc = await PDFDocument.create();
  
    // Adiciona uma nova página para a lista resumida dos alunos
    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
  
    // Adiciona os detalhes resumidos dos alunos na primeira página
    const fontSize = 12;
    const textFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const textFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    // Define as configurações do cabeçalho
    const header = {
      title: 'RELATÓRIO DA TURMA',
      subtitleLines: [
        `Instituição: ${instituicao}`,
        `Turma: ${turma}`,
      ],
      fontSize: 14,
      titleFont: await pdfDoc.embedFont(StandardFonts.HelveticaBold),
      subtitleFont: await pdfDoc.embedFont(StandardFonts.Helvetica), // Usando a fonte Helvetica para o subtítulo
    };

     // Função para desenhar o cabeçalho
    const drawHeader = (page_number) => {
      const titleWidth = header.titleFont.widthOfTextAtSize(header.title, header.fontSize);
      const titleX = width / 2 - titleWidth / 2;
      let subtitleY = height - 70; // Iniciar abaixo do título

      page.drawText(header.title, {
        x: titleX,
        y: height - 50,
        size: header.fontSize,
        font: header.titleFont,
        color: rgb(0, 0, 0),
      });

      const subtitle = header.subtitleLines.slice();
      subtitle.push(`Página ${page_number}`)
      // Desenhar cada linha do subtítulo
      subtitle.forEach(subtitleLine => {
        const subtitleWidth = header.subtitleFont.widthOfTextAtSize(subtitleLine, header.fontSize);
        const subtitleX = width / 2 - subtitleWidth / 2;

        page.drawText(subtitleLine, {
          x: subtitleX,
          y: subtitleY,
          size: 12,
          font: header.subtitleFont,
          color: rgb(0, 0, 0),
        });

        subtitleY -= 12 + 2; // Mover para a próxima linha
      });
    };

    let page_num = 1;
    // Desenha o cabeçalho
    drawHeader(page_num);

    // Define as configurações da tabela
    const table = {
      x: 50,
      y: height - 150,
      width: width - 100,
      rowHeight: 20,
      colWidths: [120, 120, 120, 150],
    };

    // Função auxiliar para quebrar o texto em várias linhas
    const breakTextIntoLines = (text, width, font, fontSize) => {
      const words = text.toString().split(' ');
      const lines = [];
      let currentLine = '';
      words.forEach(word => {
        const testLine = currentLine.length === 0 ? word : `${currentLine} ${word}`;
        const testWidth = font.widthOfTextAtSize(testLine, fontSize);
        if (testWidth > width) {
          lines.push(currentLine);
          currentLine = word;
        } else {
          currentLine = testLine;
        }
      });
      lines.push(currentLine);
      return lines;
    };

    let currentY = 0;
    let yOffset = table.y;
    // Função para desenhar uma célula da tabela
    const drawTableCell = (text, x, y, width, fontSize, isBold) => {
      const font = isBold ? textFontBold : textFont;
      page.setFont(font);
      // Quebra o texto em várias linhas se for mais largo do que a largura da célula
      const lines = breakTextIntoLines(text, width - 20, font, fontSize);
      currentY = y;
      lines.forEach(line => {
        page.drawText(line, { x, y: currentY, size: fontSize });
        currentY -= fontSize + 2; // Espaçamento entre linhas
      });
      const n_lines = lines.length;
      return n_lines;
    };

    let lineY = table.y + 2;

    const drawHorizontalLine = () => {
      page.drawLine({
        start: { x: table.x - 10, y: lineY },
        end: { x: table.x + 5 + table.width, y: lineY },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
    };
    // Função para desenhar uma linha da tabela
    const drawTableRow = (rowData, fontSize, is_bold) => {
      let xOffset = table.x;
      let n_lines = 1;
      rowData.forEach((cellText, index) => {
        const n_iter = drawTableCell(cellText, xOffset, yOffset - (table.rowHeight / 2), table.colWidths[index], fontSize, is_bold);
        xOffset += table.colWidths[index];
        if (n_iter > n_lines) {
          n_lines = n_iter;
        }
      });
      yOffset -= (table.rowHeight + (n_lines - 1)*fontSize);
      lineY = yOffset;
      drawHorizontalLine();
    };

    const drawVerticalLines = () => {
      // Desenha linhas verticais
      let lineX = table.x - 10;
      page.drawLine({
        start: { x: lineX, y: table.y + 2 },
        end: { x: lineX, y: lineY },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
      table.colWidths.forEach(width => {
        lineX += width;
        page.drawLine({
          start: { x: lineX, y: table.y + 2 },
          end: { x: lineX, y: lineY },
          thickness: 1,
          color: rgb(0, 0, 0),
        });
      });
    };

    drawHorizontalLine();
    // Adiciona cabeçalho da tabela
    drawTableRow(['Nro formando', 'Nome', 'CPF', 'Data de Nascimento'], 12, true);

    // Adiciona os detalhes resumidos dos alunos na tabela
    listaAlunos.forEach(aluno => {
      drawTableRow([aluno.id, `${aluno.primeiroNome ?? ''} ${aluno.sobrenome ?? ''}`, aluno.cpf ?? '', aluno?.dataNascimento !== null ? getDateString(aluno?.dataNascimento):('')], 8, false);
      if (lineY < 50) {
        page_num += 1;
        drawVerticalLines();
        // Adiciona uma nova página
        page = pdfDoc.addPage();
        // Atualiza yOffset para a nova página
        yOffset = table.y;
        // Desenha o cabeçalho novamente, se necessário
        drawHeader(page_num);
        lineY = table.y + 2;
        drawHorizontalLine();
        drawTableRow(['Nro formando', 'Nome', 'CPF', 'Data de Nascimento'], 12, true);
      }
    });
    
    drawVerticalLines();
  
    
    for (const aluno of listaAlunos) {
      try {
        const alunoData = await fetchAlunoData(aluno.id);
        if (!alunoData) continue;
      
        const newPage = pdfDoc.addPage();
        const { width, height } = newPage.getSize();
      
        const image = await embedImage(pdfDoc, alunoData.profile_image);
      
        newPage.drawImage(image, {
          x: 50,
          y: height - image.height/3 - 20,
          width: image.width/3,
          height: image.height/3,
        });
        
        let offsetY = height - image.height/3 - 40;
      
        // Função para desenhar parte do texto em negrito e parte em texto normal
        const drawTextWithBold = (boldText, normalText, x, y) => {
          newPage.drawText(boldText, {
            x,
            y,
            size: fontSize,
            font: textFontBold,
            color: rgb(0, 0, 0),
            bold: true, // Define o texto em negrito
          });
      
          newPage.drawText(normalText, {
            x: x + textFont.widthOfTextAtSize(boldText, fontSize) + 10, // Ajusta a posição X para começar depois do texto em negrito
            y,
            size: fontSize,
            font: textFont,
            color: rgb(0, 0, 0),
          });
        };
      
        // Adiciona os detalhes do aluno

        drawTextWithBold('Nro do formando:', `${alunoData?.pessoal?.id}`, 50, offsetY);
        offsetY -= 20;

        drawTextWithBold('Nome:', `${alunoData?.pessoal?.primeiroNome} ${alunoData?.pessoal?.sobrenome ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('RG:', `${alunoData?.pessoal?.rg ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('CPF:', `${alunoData?.pessoal?.cpf ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Email:', `${alunoData?.pessoal?.email ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Data de Nascimento:', `${alunoData?.pessoal?.dataNascimento !== null ? (getDateString(alunoData.pessoal.dataNascimento)) : ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('CEP:', `${alunoData?.endereco?.cep ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Endereço:', `${alunoData?.endereco?.endereco ?? ''}, ${alunoData?.endereco?.numero ?? ''}, ${alunoData?.endereco?.complemento ?? ''}, ${alunoData?.endereco?.bairro ?? ''}, ${alunoData?.endereco?.cidade ?? ''} - ${alunoData?.endereco?.uf ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Celular 1:', `${alunoData?.endereco?.celular ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Celular 2:', `${alunoData?.endereco?.celular_second ?? ''}`, 50, offsetY);
        offsetY -= 20;

        drawTextWithBold('Data do último download:', `${alunoData?.pessoal?.dataDownload !== null ? (getDateTimeString(alunoData.pessoal.dataDownload)) : ''}`, 50, offsetY);

        // Dados dos pais
        offsetY -= 20;
        drawTextWithBold('Dados dos Pais:', '', 50, offsetY);
        offsetY -= 20;
        drawTextWithBold('CEP:', `${alunoData?.endereco?.cepPais ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Endereço:', `${alunoData?.endereco?.enderecoPais ?? ''}, ${alunoData?.endereco?.numeroPais ?? ''}, ${alunoData?.endereco?.complementoPais ?? ''}, ${alunoData?.endereco?.bairroPais ?? ''}, ${alunoData?.endereco?.cidadePais ?? ''} - ${alunoData?.endereco?.ufPais ?? ''}`, 50, offsetY);
        offsetY -= 20;
      
        drawTextWithBold('Celular dos Pais:', `${alunoData?.endereco?.celularPais ?? ''}`, 50, offsetY);
      
        // Adicione os detalhes da instituição e turma no rodapé da página
        newPage.drawText(`Instituição: ${instituicao}`, {
          x: 50,
          y: 50,
          size: fontSize,
          font: textFont,
          color: rgb(0, 0, 0),
        });
      
        newPage.drawText(`Turma: ${turma}`, {
          x: 50,
          y: 30,
          size: fontSize,
          font: textFont,
          color: rgb(0, 0, 0),
        });
      } catch (e) {
        alert(`Erro ao gerar relatório da turma ${turma} para o aluno ${aluno.id}`);
      }
    }
    
    
  
    // Converte o documento PDF em bytes
    const pdfBytes = await pdfDoc.save();
  
    // Cria um objeto de Blob a partir dos bytes do PDF
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  
    // Cria um objeto de URL para o PDF gerado
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    // Abre uma nova janela ou aba do navegador para visualizar o PDF gerado
    window.open(pdfUrl);
  };
  
  function ordenarAlfabeticoTurmaInst(arrayMapeado) {
    return arrayMapeado.sort((a, b) => {
      const parteA = a.label.split(' - ')[1].toLowerCase();
      const parteB = b.label.split(' - ')[1].toLowerCase();
      if (parteA < parteB) {
        return -1;
      }
      if (parteA > parteB) {
        return 1;
      }
      return 0;
    });
  }
  
  
  return (
    <>
    <Container>
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <FormControl style={{ marginRight: '10px', width: '20rem' }}>
          <Autocomplete
            id="instituicao-autocomplete"
            options={ordenarAlfabeticoTurmaInst(instituicoes.map((instituicao) => ({
                label: `${instituicao.id} - ${instituicao.nome}`,
                value: instituicao.id
              })))}
            sx={{ width: '19rem' }}
            renderInput={(params) => <TextField {...params} label="Instituição" />}
            onChange={(event, newValue) => handleInstituicaoChange(newValue ? newValue : "")}
          />
        </FormControl>
        {selectedInstituicao && (
          <Button variant="contained" color="primary" style={{ marginRight: '5px' }} onClick={handleCriarTurma}>Criar turma</Button>
        )}
      </div>
      <CriarTurmaDialog open={dialogOpen} handleClose={handleCloseDialog} handleSave={handleSaveTurma} instituicaoSelecionada={selectedInstituicao} />
      {showComponent && (
        <>
          <FormControl fullWidth style={{ marginBottom: '20px' }}>
            <Autocomplete
              id="turma-autocomplete"
              options={ordenarAlfabeticoTurmaInst(turmas.map((turma) => ({
                  label: `${turma.nro_contrato} - ${turma.curso}`,
                  value: turma.id
                })))}
              renderInput={(params) => <TextField {...params} label="Turma" />}
              onChange={(event, newValue) => handleTurmaChange(newValue ? newValue.value : "")}
            />
          </FormControl>
          {selectedTurma !== '' ? (
            <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Button variant="contained" color="primary" onClick={handleShowTurmaDetails}>Dados da turma</Button>
                <Button variant="contained" color="primary" onClick={() => {setShowCreateUser(true);}} style={{marginLeft: '10px'}}>Inscrever formando</Button>
                <Button variant="contained" color="primary" onClick={() => {generatePdf(findById(instituicoes,selectedInstituicao)['nome'], `${findById(turmas, selectedTurma)['nro_contrato']} - ${findById(turmas, selectedTurma)['curso']}`, filteredData);}} style={{marginLeft: '10px'}}>Gerar relatório de turma</Button>
                <Button variant="contained" color="primary" onClick={() => {downloadAllProfilePicture(filteredData)}} style={{marginTop: '10px'}}>Baixar fotos de perfil</Button>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Localizar formando"
                  id="search-formando-adm"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID Formando</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>CPF</TableCell>
                    <TableCell>Data de Nascimento</TableCell>
                    <TableCell>Detalhes do Aluno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((aluno) => (
                    <TableRow key={aluno.id}>
                      <TableCell>{aluno.id}</TableCell>
                      <TableCell>{aluno.primeiroNome} {aluno.sobrenome}</TableCell>
                      <TableCell>{aluno.cpf}</TableCell>
                      <TableCell>{getDateString(aluno.dataNascimento)}</TableCell>
                      <TableCell>
                        <Button variant="outlined" color="primary" onClick={() => {setUserDetailId(aluno.id); setUserDetailOpen(true)}}>Detalhes do aluno</Button>
                        {admin.isSuperAdmin ? (
                          <Button variant="outlined" color="primary" style={{marginLeft : "5px"}} onClick={() => {handleDeleteFormando(aluno.id)}}>Excluir</Button>
                        ):(
                          null
                        )} 
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </>
          ):(null)}
        </>
      )}
    </Container>
    <TurmaDialog
      open={showTurmaDetails}
      onClose={handleCloseTurmaDetails}
      turmaData={turma_data}
    />
    <UserProfileDialog
      userId={userDetailId}
      open={userDetailOpen}
      onClose={handleCloseUserDetails}
    />
    <CreateUser 
      open={showCreateUser}
      handleClose={() => {setShowCreateUser(false);}}
      idTurma={selectedTurma}
      reload={() =>{carregarAlunos(selectedTurma)}}/>
    </>
  );
};

export default Turma;
