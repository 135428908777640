import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';

// Criar o contexto
const AdminContext = createContext();

// Provedor do contexto para envolver os componentes
export const AdminProvider = ({ children }) => {
  const [instituicoes, setInstituicoes] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const { admin, adminLoggedIn } = useContext(AuthContext);
  const [actionTrigger, setActionTrigger] = useState(false);
  const [isFetchingInst, setIsFetchingInst] = React.useState(false);
  const [isFetchingAdm, setIsFetchingAdm] = React.useState(false);

  useEffect(() => {
    // Função para buscar as instituições da API
    const fetchInstituicoes = async () => {
      if (isFetchingInst) {
        return;
      }
    
      setIsFetchingInst(true);
      try {
        const response = await axios.get(baseUrl + 'admins/list-institution', {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          })
        setInstituicoes(response.data.instituicoes.sort((a, b) => {
          const concatenacaoA = (a.nome).toLowerCase();
          const concatenacaoB = (b.nome).toLowerCase();
          
          return concatenacaoA.localeCompare(concatenacaoB);
        })); // Atualiza o estado das instituições com os dados da API
      } catch (error) {
        alert('Erro ao buscar instituições:', error);
      } finally {
        setIsFetchingInst(false);
      }
    };

    const fetchAdmins = async () => {
      if (isFetchingAdm) {
        return;
      }
    
      setIsFetchingAdm(true);
      try {
        const response = await axios.get(baseUrl + 'admins/list-admins', {
            headers:{
              "Authorization": "Bearer "+admin.idToken
            }
          })
        setAdminList(response.data.admins); // Atualiza o estado das instituições com os dados da API
      } catch (error) {
        alert('Erro ao buscar administradores:', error);
      } finally {
        setIsFetchingAdm(false);
      }
    };

    if (admin) {
      // Chamada da função para buscar instituições
      fetchInstituicoes();
      if (admin.isSuperAdmin) {
        fetchAdmins();
      }
    }
  }, [adminLoggedIn, actionTrigger]); // Passando um array vazio como segundo argumento para garantir que o useEffect seja executado apenas uma vez

  return (
    <AdminContext.Provider value={{instituicoes, setInstituicoes, actionTrigger, setActionTrigger, adminList, setAdminList}}>
      {children}
    </AdminContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useAdminData = () => useContext(AdminContext);

