// DataContext.js
import { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const resetFormData = () => {
    setFormData({});
  };

  return (
    <DataContext.Provider value={{ formData, updateFormData, resetFormData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (initialData = {}) => {
  const [initialized, setInitialized] = useState(false); // Flag para garantir que a inicialização só ocorra uma vez

  // Obtém o contexto de dados
  const context = useContext(DataContext);

  // Verifica se a inicialização já ocorreu
  if (!initialized && Object.keys(initialData).length > 0) {
    // Define o estado inicial com os valores passados
    context.updateFormData(initialData);
    setInitialized(true); // Atualiza a flag para indicar que a inicialização ocorreu
  }

  return context;
};