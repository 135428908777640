import React, {useContext, useState} from "react";
import { Dialog, DialogContent, Typography, Button, Grid, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Input, FormControl, InputLabel, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { AuthContext } from "../authContext";
import { useAdminData } from "./adminContext";
import { baseUrl } from "../../App";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useData } from "../registrar_aluno/DataContext";
import axios from "axios";
import LoadingOverlay from "../loading";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000.000.000-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
    
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CelMaskCustom = React.forwardRef(function CelMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
  
CelMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
  
const CepMaskCustom = React.forwardRef(function CepMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000-000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
  
CepMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
    
const UfMaskCustom = React.forwardRef(function UfMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="**"
      definitions={{
        '*': /[A-Za-z]/,
      }}
      inputRef={ref}
      onAccept={(value) => {
        const uppercaseValue = value.toUpperCase(); // Converter o valor para maiúsculas
        onChange({ target: { name: props.name, value: uppercaseValue } }); // Chamar onChange com o valor convertido
      }}
      overwrite
    />
  );
});
  
UfMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CreateUser = ({ open, handleClose, idTurma, reload }) => {
    const { formData, updateFormData, resetFormData } = useData({id_turma: idTurma});
    const { admin } = useContext(AuthContext);
    const { actionTrigger, setActionTrigger } = useAdminData();
    const [onLoad, setOnLoad] = useState(false);

    const MAX_WIDTH = 1280;
    const MAX_HEIGHT = 720;
    const MAX_QUALITY = 0.7;

    const numberStyles = {
      input: {
        '-webkit-appearance': 'textfield', // Remover as setinhas no Safari
        '-moz-appearance': 'textfield', // Remover as setinhas no Firefox
        appearance: 'textfield' // Remover as setinhas no Chrome e Edge
      }
    };
  
    const handleRespostaChange = (event) => {
      const valor = event.target.value;
      // Se "Sim" for selecionado, definimos a variável respostaSim como true
      if (valor === 'sim') {
        updateFormData({moraComPais: true})
      } else {
        updateFormData({moraComPais: false})
      }
    };

    const handleImageUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
              const canvas = document.createElement('canvas');
              let width = img.width;
              let height = img.height;
      
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
      
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);
      
              canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.onloadend = function () {
                  const base64Data = reader.result;
                  updateFormData({ image: base64Data });
                };
                reader.readAsDataURL(blob);
              }, 'image/jpeg', MAX_QUALITY);
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      };
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      updateFormData({ [name]: value });
      updateFormData({id_turma:idTurma}); // Coloquei aqui por que precisa, não estava atualizando no clique
      if ((value.length > 0) || (value !== '')){
          updateFormData({ [name+'_err']: false });
      }else{
          updateFormData({ [name+'_err']: true });
      }
    };

    const handleCep = (event) => {
        event.preventDefault();
        fetch(`https://viacep.com.br/ws/${formData.cep}/json/`)
        .then(response => {
            if (!response.ok) {
                alert('Erro ao consultar o CEP')
            }
            return response.json();
          })
          .then(data => {
            if (data && !data.erro) {
              updateFormData({endereco: data.logradouro, endereco_err: false})
              updateFormData({bairro: data.bairro, bairro_err: false})
              updateFormData({cidade: data.localidade, cidade_err: false})
              updateFormData({uf: data.uf, uf_err: false})
            } else {
                alert('CEP não encontrado ou inválido')
            }
          });
        }

    const handleCepPais = (event) => {
        event.preventDefault();
        fetch(`https://viacep.com.br/ws/${formData.cepPais}/json/`)
        .then(response => {
            if (!response.ok) {
                alert('Erro ao consultar o CEP')
            }
            return response.json();
          })
          .then(data => {
            if (data && !data.erro) {
                updateFormData({enderecoPais: data.logradouro, enderecoPais_err: false})
                updateFormData({bairroPais: data.bairro, bairroPais_err: false})
                updateFormData({cidadePais: data.localidade, cidadePais_err: false})
                updateFormData({ufPais: data.uf, ufPais_err: false})
            } else {
                alert('CEP não encontrado ou inválido')
            }
          });
        }
    
    const handleSend = () => {
        
        // Implementar a lógica para enviar a imagem
        try {
            if (formData.image && formData.primeiroNome && formData.sobrenome) {
                setOnLoad(true);
                fetch(baseUrl + 'admins/register-user', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer '+admin.idToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
                }).then(response => {
                if (response.ok) {
                    alert("Formando cadastrado com sucesso!");
                    setTimeout(2000);
                    reload();
                    resetFormData();
                    handleClose();
                    setOnLoad(false);
                }
                }).catch(error => {
                    alert("Falha ao cadastrar formando!");
                    setOnLoad(false);
                })
            } else {
                alert("Preencha todos os campos requeridos (foto, nome e sobrenome)")
            }
        } catch (error) {
          console.error('Erro ao enviar dados para a API:', error);
        }
      };

    return (
        <Dialog open={open} onClose={() =>{resetFormData(); handleClose();}} maxWidth="xl">
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Grid container spacing={2}>
                            {formData.image ? (
                                <>
                                <Grid item xs={12}>
                                    <img src={formData.image} alt="Foto de Perfil" style={{ maxWidth: '100%', maxHeight: '100%',  marginTop: '5%' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" component="label">
                                        Refazer o upload
                                        <input type="file" hidden onChange={handleImageUpload} />
                                    </Button>
                                </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Button variant="contained" component="label">
                                        Upload de imagem
                                        <input type="file" hidden onChange={handleImageUpload} />
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    id="id"
                                    name="id"
                                    label="Código do formando"
                                    value={formData.id || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    autoComplete="id"
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <React.Fragment>
                            <Typography variant="h6" gutterBottom>
                                Informações Pessoais
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="primeiroNome"
                                        name="primeiroNome"
                                        label="Primeiro Nome"
                                        value={formData.primeiroNome || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="primeiro-nome"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="sobrenome"
                                        name="sobrenome"
                                        label="Sobrenome"
                                        value={formData.sobrenome || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="segundo-nome"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="rg"
                                        name="rg"
                                        label="RG"
                                        value={formData.rg || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-rg"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="cpf-value" variant='standard'>CPF</InputLabel>
                                        <Input
                                            required
                                            id="cpf-value"
                                            name="cpf"
                                            value={formData.cpf || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="standard"
                                            inputComponent={TextMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="E-mail"
                                        value={formData.email || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-email"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth >
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                            <DatePicker
                                                id="data-nascimento"
                                                name="dataNascimento"
                                                label="Data de Nascimento"
                                                fullWidth
                                                variant="standard"
                                                value={formData.dataNascimento}
                                                onChange={(newValue) => updateFormData({ dataNascimento: newValue, dataNascimento_err: false })}
                                                format="DD-MM-YYYY"
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4}>
                        <React.Fragment>
                            <Typography variant="h6" gutterBottom mb={3}>
                                Endereço e Contato
                            </Typography>
                            <Grid container spacing={3} gutterBottom>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="cep-value" variant='standard'>CEP</InputLabel>
                                        <Input
                                            id="cep-value"
                                            name="cep"
                                            value={formData.cep || ''}
                                            onChange={handleChange}
                                            onBlur={handleCep}
                                            fullWidth
                                            variant="standard"
                                            inputComponent={CepMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="endereco"
                                        name="endereco"
                                        label="Endereço"
                                        value={formData.endereco || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-endereco"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="numero"
                                        name="numero"
                                        label="Número"
                                        value={formData.numero || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-numero"
                                        variant="standard"
                                        type="number"
                                        inputProps={{
                                            inputMode: 'numeric',
                                            style: numberStyles.input
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="complemento"
                                        name="complemento"
                                        label="Complemento"
                                        value={formData.complemento || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-complemento"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="bairro"
                                        name="bairro"
                                        label="Bairro"
                                        value={formData.bairro || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-bairro"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="cidade"
                                        name="cidade"
                                        label="Cidade"
                                        value={formData.cidade || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="valor-cidade"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="uf-value" variant='standard'>UF</InputLabel>
                                        <Input
                                            id="uf"
                                            name="uf"
                                            value={formData.uf || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="standard"
                                            inputComponent={UfMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="celular-value" variant='standard'>Celular/WhatsApp</InputLabel>
                                        <Input
                                            required
                                            id="celular-value"
                                            name="celular"
                                            value={formData.celular || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="standard"
                                            inputComponent={CelMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="celular2-value" variant='standard'>Celular 2</InputLabel>
                                        <Input
                                            id="celular2-value"
                                            name="celular2"
                                            value={formData.celular2 || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="standard"
                                            inputComponent={CelMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Formando mora com os pais/familiares?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={handleRespostaChange}
                                            value = {formData.moraComPais === false ? 'nao' : 'sim'}
                                        >
                                            <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                                            <FormControlLabel value="nao" control={<Radio />} label="Não" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {(!formData.moraComPais && formData.moraComPais !== undefined) && (
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom mt={3} mb={3}>
                                Endereço e Contato dos Pais/Outros endereços de contato
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="cep-value" variant='standard'>CEP</InputLabel>
                                            <Input
                                                id="cep-value-pais"
                                                name="cepPais"
                                                value={formData.cepPais || ''}
                                                onChange={handleChange}
                                                onBlur={handleCepPais}
                                                fullWidth
                                                variant="standard"
                                                inputComponent={CepMaskCustom}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="endereco-pais"
                                            name="enderecoPais"
                                            label="Endereço"
                                            value={formData.enderecoPais || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            autoComplete="valor-endereco-pais"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="numero-pais"
                                            name="numeroPais"
                                            label="Número"
                                            value={formData.numeroPais || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            autoComplete="valor-numero-pais"
                                            variant="standard"
                                            type="number"
                                            inputProps={{
                                                inputMode: 'numeric',
                                                style: numberStyles.input
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="complemento-pais"
                                            name="complementoPais"
                                            label="Complemento"
                                            value={formData.complementoPais || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            autoComplete="valor-complemento-pais"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="bairro-pais"
                                            name="bairroPais"
                                            label="Bairro"
                                            value={formData.bairroPais || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            autoComplete="valor-bairro-pais"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="cidadePais"
                                            name="cidadePais"
                                            label="Cidade"
                                            value={formData.cidadePais || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            autoComplete="valor-cidade-pais"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="ufPais-value" variant='standard' >UF</InputLabel>
                                        <Input
                                            id="uf-pais"
                                            name="ufPais"
                                            value={formData.ufPais || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="standard"
                                            inputComponent={UfMaskCustom}
                                        />
                                    </FormControl>
                                </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="celular-pais-value" variant='standard'>Celular/WhatsApp</InputLabel>
                                            <Input
                                                id="celular-pais-value"
                                                name="celularPais"
                                                value={formData.celularPais || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                variant="standard"
                                                inputComponent={CelMaskCustom}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            )
                        }
                        </React.Fragment>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => {resetFormData(); handleClose();}}>Cancelar</Button>
                <Button variant="contained" onClick={() => {handleSend();}}>Cadastrar</Button>
            </DialogActions>
            <LoadingOverlay open={onLoad}/>
        </Dialog>
    )
};

export default CreateUser;