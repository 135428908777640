import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FormFuncionarios from './gerenciarFuncionario';
import { useAdminData } from './adminContext';
import axios from 'axios';
import { baseUrl } from '../../App';
import { AuthContext } from '../authContext';

const Funcionario = () => {
  // Exemplo de array de funcionários
  const [formFuncOpen, setFormFuncOpen] = React.useState(false);
  const [formFuncEditable, setFormFuncEditable] = React.useState(true);
  const {adminList, actionTrigger, setActionTrigger} = useAdminData();
  const { admin } = React.useContext(AuthContext);

  const funcionarios = adminList;
  const handleRegister = () => {
    setFormFuncEditable(true);
    setFormFuncOpen(true);
    setActionTrigger(!actionTrigger);
  };

  const handleDelete = (email, id) => {
    axios.post(baseUrl + 'admins/delete-admin',{
      "id": id,
      "email": email
    }, {
      headers:{
        "Authorization": "Bearer "+admin.idToken
      }
    })
      .then(response => {
        alert("Funcionário deletado com sucesso!");
        setActionTrigger(!actionTrigger);
      })
      .catch(error => {
        alert('Erro:', error);
      });
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleRegister}>Criar funcionário</Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CPF</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Departamento</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funcionarios.map((funcionario) => (
              <TableRow key={funcionario.cpf}>
                <TableCell>{funcionario.cpf}</TableCell>
                <TableCell>{funcionario.nome}</TableCell>
                <TableCell>{funcionario.departamento}</TableCell>
                <TableCell>{funcionario.email}</TableCell>
                <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => {handleDelete(funcionario.email, funcionario.id)}}>Excluir</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormFuncionarios open={formFuncOpen} handleClose={() => {setFormFuncOpen(false)}} editable={formFuncEditable}/>
    </div>
  );
};

export default Funcionario;
