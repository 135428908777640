import * as React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useData } from './DataContext';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import dayjs from 'dayjs';

export default function Review() {

  // Pegar os dados do formulário
  const { formData } = useData();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
          Informações Pessoais
      </Typography>
      <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <TextField
                disabled
                label="Primeiro Nome"
                value={formData.primeiroNome}
                fullWidth
                variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={6}>
              <TextField
                  label="Sobrenome"
                  value={formData.sobrenome }
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={4}>
              <TextField
                  label="RG"
                  value={formData.rg }
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={4}>
              <TextField
                  label="CPF"
                  value={formData.cpf }
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={4}>
              <TextField
                  label="Data de nascimento"
                  value={dayjs(formData.dataNascimento).format('DD/MM/YYYY') }
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
                  label="E-mail"
                  value={formData.email }
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom mt={3} mb={3}>
        Endereço e Contato
      </Typography>
      <Grid container spacing={3} gutterBottom>
        <Grid item xs={12} sm={6}>
            <TextField
                label="CEP"
                value={formData.cep || ''}
                disabled
                fullWidth
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                label="Endereço"
                value={formData.endereco || ''}
                disabled
                fullWidth
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Número"
                value={formData.numero || ''}
                disabled
                fullWidth
                autoComplete="valor-numero"
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField
                label="Complemento"
                value={formData.complemento || ''}
                disabled
                fullWidth
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={5}>
            <TextField
                label="Bairro"
                value={formData.bairro || ''}
                disabled
                fullWidth
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={5}>
            <TextField
                label="Cidade"
                value={formData.cidade || ''}
                disabled
                fullWidth
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={2}>
            <TextField
                label="UF"
                value={formData.uf || ''}
                disabled
                fullWidth
                variant="standard"
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
              label='Celular/WhatsApp'
              value={formData.celular || ''}
              fullWidth
              variant="standard"
              disabled
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
              label='Celular 2'
              value={formData.celular2 || ''}
              fullWidth
              variant="standard"
              disabled
            />
        </Grid>
        <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Você mora com seus pais/familiares?</FormLabel>
              <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value = {formData.moraComPais === false ? 'nao' : 'sim'}
              >
                  <FormControlLabel value="sim" control={<Radio />} label="Sim" disabled />
                  <FormControlLabel value="nao" control={<Radio />} label="Não" disabled/>
              </RadioGroup>
            </FormControl>
        </Grid>
      </Grid>
      {(!formData.moraComPais && formData.moraComPais !== undefined) && (
      <React.Fragment>
        <Typography variant="h6" gutterBottom mt={3} mb={3}>
        Endereço e Contato dos Pais/Outros endereços de contato
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <TextField
                  label="CEP"
                  value={formData.cepPais || ''}
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={8}>
              <TextField
                  label="Endereço"
                  value={formData.enderecoPais || ''}
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={4}>
              <TextField
                  label="Número"
                  value={formData.numeroPais || ''}
                  disabled
                  fullWidth
                  autoComplete="valor-numero"
                  variant="standard"
              />
          </Grid>
          <Grid item xs={12} sm={12}>
              <TextField
                  label="Complemento"
                  value={formData.complementoPais || ''}
                  disabled
                  fullWidth
                  variant="standard"
              />
          </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Bairro"
                value={formData.bairroPais || ''}
                disabled
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Cidade"
                value={formData.cidadePais || ''}
                disabled
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="UF"
                value={formData.ufPais || ''}
                disabled
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Celular/WhatsApp"
                value={formData.celularPais || ''}
                disabled
                fullWidth
                variant="standard"
              />
            </Grid>
        </Grid>
      </React.Fragment>
      )}
    </React.Fragment>
  );
}