import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'dataVencimento', headerName: 'Data de Vencimento', width: 200 },
  { field: 'valor', headerName: 'Valor', width: 150 },
  { field: 'foiPago', headerName: 'Foi Pago', width: 150, renderCell: StatusCell },
  { field: 'emitirBoleto', headerName: 'Emitir Boleto', width: 150, renderCell: EmitirBoletoButton },
];

const rows = [];

function StatusCell(params) {
  const status = params.value === 'Sim' ? 'Pago' : 'Não Pago';
  const color = params.value === 'Sim' ? 'green' : 'red';

  return <div style={{ color }}>{status}</div>;
}

function EmitirBoletoButton(params) {
  const { id, foiPago } = params.row;
  
  if (foiPago === 'Sim') {
    return null; // Não renderizar o botão se o boleto já foi pago
  }

  return <Button variant="contained" size="small" onClick={() => emitirBoleto(id)}>Emitir</Button>;
}

function emitirBoleto(id) {
  console.log(`Emitindo boleto para o ID: ${id}`);
}

const Boletos = () => {
  return (
    <Box sx={{ width: '100%', height: '100%', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Boletos a serem pagos
      </Typography>
      <Box sx={{ width: '100%', height: 'calc(100vh - 160px)', overflowX: 'auto' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection={false}
          headerClassName="header"
          disableColumnMenu
        />
      </Box>
    </Box>
  );
}

export default Boletos;
