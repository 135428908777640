import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { mainListItems } from './listItems';
import Turma from './turmas';
import Instituicao from './instituicoes';
import Funcionario from './funcionarios';
import Login from '../login';
import axios from 'axios';
import { AuthContext } from '../authContext';
import { baseUrl } from '../../App';
import UploadAccordion from './uploadProgress';
import { Fab, Paper } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import { useUploadContext } from './uploadContext';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://flashfotosformaturas.com.br/index.html">
          Flash Fotos Formaturas
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AppAdmin() {
  const [open, setOpen] = React.useState(false);
  const [paginaSelecionada, setPaginaSelecionada] = React.useState('Turmas');
  const {  adminLoggedIn, signOutAdmin, refreshAdmin, admin } = React.useContext(AuthContext);
  let [isRefreshing, setIsRefreshing] = React.useState(false);
  const [uploadDrawerOpen, setUploadDrawerOpen] = React.useState(false);
  const [state, setState] = React.useState({
    verticalSnack: "bottom",
    horizontalSnack: "right",
  });
  const { verticalSnack, horizontalSnack} = state;
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const { uploadProgress } = useUploadContext();

  React.useEffect(() => {
    
    const calculateGlobalProgress = (username, userData) => {
      if (!userData) return 0;
      
      let numPartsCompleted = 0;
      let numParts = 0;
      for (let file in userData) {
        const numPartsFile = Object.keys(userData[file].parts).length;
        numParts += numPartsFile;
        for (let i = 0; i < numPartsFile; i++) {
          if (userData[file].parts[i]) {
            numPartsCompleted++;
          }
        }
      }
      
      if ((numPartsCompleted/numParts)*100 === 100){
        setSnackOpen(true);
      setSnackbarMessage(`Upload de fotos do formando ${username} concluído com sucesso!`);
      };
    };

    if (Object.keys(uploadProgress).length > 0){
      Object.keys(uploadProgress).map((username, index) => (calculateGlobalProgress(username, uploadProgress[username])));
    }
  }, [uploadProgress]);


  React.useEffect(() => {
    
    const refreshToken = async () => {
      if (isRefreshing) {
        return;
      }
    
      setIsRefreshing(true);

      let suffix = '';
      let refresh_token = '';
      if (adminLoggedIn){
        suffix = 'admins/refresh';
        refresh_token = admin.refreshToken;
        try {
          // Faça a solicitação para obter um novo token de atualização
          const response = await axios.post(`${baseUrl}${suffix}`, {
            refreshToken: refresh_token
          });
  
          // Atualize o token de acesso com o novo token obtido
          const data = {
            "idToken": response.data.user.idToken,
            "refreshToken": response.data.user.refreshToken
          }
          if (adminLoggedIn){
            refreshAdmin(data);
          }
          
        } catch (error) {
          if (error?.response?.data?.message){
            alert('Erro ao solicitar o novo token de atualização:', error.response.data.message);
          }
          console.log(error)
          signOutAdmin();
        } finally {
          setIsRefreshing(false);
        }
      } 
    };
    
    setInterval(refreshToken, 20*60*1000);
    // Execute refreshToken imediatamente ao montar o componente
    refreshToken();

  }, [adminLoggedIn]);

  function getPageContent(page) {
    if (page === 'Turmas'){
      return <Turma />
    }
    if (page === 'Instituicao'){
      return <Instituicao />
    }
    if (page === 'Funcionario'){
      return <Funcionario />
    }
  }

  const handleItemClick = (pagina) => {
    setPaginaSelecionada(pagina);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleUploadDrawer = () => {
    setUploadDrawerOpen(!uploadDrawerOpen);
  };

  const handleLogout = () => {
    signOutAdmin();
  }

  return (
    <>
    {adminLoggedIn ? (
      <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Área interna
            </Typography>
            <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DrawerStyled variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems(paginaSelecionada, handleItemClick, admin.isSuperAdmin)}
          </List>
        </DrawerStyled>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {getPageContent(paginaSelecionada)}
            <Copyright sx={{ pt: 4 }} />
          </Container>
          <Drawer 
            anchor='bottom'
            open={uploadDrawerOpen}
            onClose={toggleUploadDrawer}
            id='uploadDrawer'
            sx={{maxWidth:1/2, maxHeight:1/2}}
            >
              <Box
                borderLeft="4px solid #ccc"
                borderRight="4px solid #ccc"
                padding="50px"
              >
                <UploadAccordion />
              </Box>
          </Drawer>
          <Fab color='primary' aria-label='Upload' className='uploadFab' onClick={toggleUploadDrawer}
            style={{position: 'fixed', bottom: '20px', right:'20px'}}>
            <BackupIcon />
          </Fab>
          <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            onClose={() => {setSnackOpen(false)}}
            message={snackbarMessage}
            key={verticalSnack + horizontalSnack}
          />
        </Box>
      </Box>
    </ThemeProvider>
    ):(
      <Login type="admin"/>
    )}
    </>
  );
}