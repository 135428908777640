import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Input, FormControl, InputLabel, Autocomplete } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import { useData } from './DataContext';
import axios from 'axios';
import { baseUrl } from '../../App';


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000.000.000-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
  
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Personal() {

    const { formData, updateFormData } = useData();
    const [turmas, setTurmas] = React.useState([]);

    React.useEffect(() => {
        const fetchTurmas = async () => {
          try {
            console.log(formData.token)
            const response = await axios.get(baseUrl + 'admins/list-class?token=' + formData.token)
            setTurmas(response.data.turmas);
          } catch (error) {
            alert('Erro ao buscar turmas:', error);
          }
        };
      
        if (formData?.token !== undefined){
            fetchTurmas();
        }
      }, [formData.token])

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData({ [name]: value });
        if ((value.length > 0) || (value !== '')){
            updateFormData({ [name+'_err']: false });
        }else{
            updateFormData({ [name+'_err']: true });
        }
    };

    const handleChangeTurma = (value) => {
        updateFormData({ id_turma: value });
        if ((value.length > 0) || (value !== '')){
            updateFormData({ id_turma_err: false });
        }else{
            updateFormData({ id_turma_err: true });
        }
    };

    return (
        <React.Fragment>
            <FormControl fullWidth style={{ marginBottom: '50px' }}>
                <Autocomplete
                    freeSolo
                    id="turma-autocomplete"
                    renderInput={(params) => <TextField {...params} label="Turma/Curso" variant="standard" error = {formData.id_turma_err || false} required />}
                    onChange={(event, newValue) => handleChangeTurma(newValue ? newValue.value : "")}
                    options={turmas.map((turma) => ({
                        label: `${turma.nro_contrato} - ${turma.curso}`,
                        value: turma.id
                      }))}
                />
            </FormControl>
            <Typography variant="h6" gutterBottom>
                Informações Pessoais
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="primeiroNome"
                        name="primeiroNome"
                        label="Primeiro Nome"
                        value={formData.primeiroNome || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="primeiro-nome"
                        variant="standard"
                        error = {formData.primeiroNome_err || false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="sobrenome"
                        name="sobrenome"
                        label="Sobrenome"
                        value={formData.sobrenome || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="segundo-nome"
                        variant="standard"
                        error = {formData.sobrenome_err || false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="rg"
                        name="rg"
                        label="RG"
                        value={formData.rg || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-rg"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error = {formData.cpf_err || false}>
                        <InputLabel htmlFor="cpf-value" variant='standard' required>CPF</InputLabel>
                        <Input
                            required
                            id="cpf-value"
                            name="cpf"
                            value={formData.cpf || ''}
                            onChange={handleChange}
                            fullWidth
                            variant="standard"
                            inputComponent={TextMaskCustom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        name="email"
                        label="E-mail"
                        value={formData.email || ''}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="valor-email"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormControl fullWidth >
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DatePicker
                                required
                                id="data-nascimento"
                                name="dataNascimento"
                                label="Data de Nascimento*"
                                fullWidth
                                variant="standard"
                                value={formData.dataNascimento}
                                onChange={(newValue) => updateFormData({ dataNascimento: newValue, dataNascimento_err: false })}
                                format="DD-MM-YYYY"
                                slotProps={{
                                    textField: {
                                        error: formData.dataNascimento_err,
                                    },
                                  }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}